import { Dir } from '../../dirs';
import http from '../../../utils/http';
import { UpdateFieldValuesPayload } from '../index';

export function fetchIntelligentFields({ id }: Pick<Dir, 'id'>) {
  return http.get(`api/intelligence-layer/${id}`);
}

export function updateIntelligentFields({
  dirId,
  fields
}: UpdateFieldValuesPayload) {
  return http.put(`api/intelligence-layer/${dirId}/sync`, fields);
}
