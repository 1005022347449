import { takeLatest, put } from 'redux-saga/effects';
import { createApiHandler } from '../@common/sagas/create-api-handler';
import {
  fetchUsers,
  readUser,
  createUser,
  updateUser,
  deleteUser,
  patchUpdateUser
} from './service/api';
import { usersActions } from './actions';
import { transform } from '../../utils/transform-entities';
import { User, UserStatusChangePayload } from './index';

const handleFetchUsers = createApiHandler({
  routine: usersActions.fetch,
  provider: fetchUsers,
  responseMiddleware: users => ({
    items: transform(users),
    order: users.map((user: User) => user.id)
  })
});

const handleUserRead = createApiHandler({
  routine: usersActions.read,
  provider: readUser
});

const handleUserCreate = createApiHandler({
  routine: usersActions.create,
  provider: createUser
});

const handleUserUpdate = createApiHandler({
  routine: usersActions.update,
  provider: updateUser
});

const handleUserDelete = createApiHandler({
  routine: usersActions.delete,
  provider: deleteUser
});

const handleUserStatusChange = createApiHandler({
  routine: usersActions.statusChange,
  provider: ({ user, status }: UserStatusChangePayload) => {
    return patchUpdateUser({
      id: user.id,
      enabled: status
    });
  }
});

function* handleUserDeleteSuccess() {
  yield put(usersActions.fetch.trigger());
}

export default function*() {
  yield takeLatest(usersActions.fetch.TRIGGER, handleFetchUsers);
  yield takeLatest(usersActions.read.TRIGGER, handleUserRead);
  yield takeLatest(usersActions.create.TRIGGER, handleUserCreate);
  yield takeLatest(usersActions.update.TRIGGER, handleUserUpdate);
  yield takeLatest(usersActions.delete.TRIGGER, handleUserDelete);
  yield takeLatest(usersActions.delete.SUCCESS, handleUserDeleteSuccess);
  yield takeLatest(usersActions.statusChange.TRIGGER, handleUserStatusChange);
}
