import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { useTranslation } from 'utils/translation';
import { PasswordChangeForm, Form } from '../components/PasswordChangeForm';
import { Alert } from '../../../components';
import { useProfilePasswordChange } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    margin: theme.spacing(2, 0)
  }
}));

interface Props {
  onCancel: () => void;
}

export const ProfilePasswordChange = ({ onCancel }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('users');
  const bagRef = useRef<Form['Bag']>();

  const {
    request,
    error,
    validationErrors,
    success
  } = useProfilePasswordChange();

  useEffect(() => {
    if (bagRef.current) {
      bagRef.current.setErrors(validationErrors || {});
    }
  }, [validationErrors, bagRef]);

  const handleSubmit = async (values: Form['Values'], bag: Form['Bag']) => {
    bagRef.current = bag;
    await request({
      currentPassword: values.currentPassword,
      password: values.password
    });
    bag.setSubmitting(false);
  };

  if (success) {
    return (
      <Alert
        className={classes.alert}
        onClose={onCancel}
        variant="success"
        message={t('Password has been changed')}
      />
    );
  }

  return (
    <div>
      {error && (
        <Alert
          className={classes.alert}
          variant="error"
          message={t('Error occurred')}
        />
      )}
      <PasswordChangeForm
        currentPasswordRequired
        onSubmit={handleSubmit}
        onCancel={onCancel}
      />
    </div>
  );
};
