export default Object.freeze({
  app: {
    providers: 'app_providers',
    extraRoutes: 'app_extra_routes',
    extraPublicRoutes: 'app_extra_public_routes',
    rootComponent: 'app_root_component'
  },
  navbar: {
    content: {
      before: 'navbar_content_before',
      after: 'navbar_content_after'
    },
    items: 'navbar_items',
    usersTitle: 'navbar_items_users_title',
    myDiskTitle: 'navbar_items_my_disk_title',
    reportsTitle: 'navbar_items_reports_title',
    settingsTitle: 'navbar_items_my_settings_title',
    itemsOrder: 'navbar_items_order',
    actions: 'navbar_actions',
    defaultActions: 'navbar_default_actions',
    actionsVisibility: 'navbar_actions_visibility'
  },
  directories: {
    availableTypes: 'available_directory_types',
    contextualViewModelEnabled: 'contextual_view_mode_enabled'
  },
  users: {
    form: {
      extraFields: 'users.form.extra_fields',
      availableRoles: 'users.form.available_roles'
    }
  },
  settings: {
    extraCrudViews: 'settings_extra_crud_views'
  }
});
