import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '../../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    progress: {
      margin: theme.spacing(2, 2, 2, 0)
    }
  })
);

interface Props {
  progress?: number;
}

const Progress: React.FC<Props> = ({ progress = 0 }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CircularProgress
        className={classes.progress}
        variant={progress === 0 || progress === 1 ? undefined : 'static'}
        value={progress * 100}
      />
    </React.Fragment>
  );
};

export const UploadProgress = React.memo(Progress, (prev, next) => {
  return prev.progress === next.progress;
});
