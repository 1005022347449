import React from 'react';
import { Dir } from '../index';
import { Loader, TreeSelect } from '../../../components';
import { useQuery } from 'react-query';
import http from 'utils/http';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { useTranslation } from 'utils/translation';

export type DirView = {
  id: Dir['id'];
  title: string;
  parentId: Dir['id'] | null;
  path: Dir['id'][];
};

type Props = {
  placeholder?: string;
  value: Nullable<Pick<Dir, 'id'>>;
  onChange(dir: Nullable<Dir>): void;
  filter?(dir: DirView): boolean;
};

const DirectoryPicker: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  filter
}) => {
  const { t } = useTranslation('myDisk');

  const { data: dirs = [], isLoading } = useQuery<DirView[]>(
    'app_directory_dirs_preload',
    () => {
      return http.get<never, DirView[]>('/api/directory/preload');
    },
    {
      refetchInterval: false
    }
  );

  const handleSelectedDirChange = (dir: Dir | any, nodes: any[]) => {
    if (nodes.length) {
      onChange(dir as Dir);
    } else {
      onChange(null);
    }
  };

  const treeData = React.useMemo(() => {
    let flatData = dirs || [];

    if (filter) {
      flatData = flatData.filter(filter);
    }

    flatData = flatData.map(node => ({
      ...node,
      value: node.id,
      key: node.id,
      label: node.title,
      checked: value && value.id === node.id
    }));

    const data = getTreeFromFlatData({
      // @ts-ignore
      flatData: flatData,
      getParentKey: node => {
        // @ts-ignore
        return node.parentId || '0';
      }
    });

    data.unshift({
      id: 0,
      label: t('My Disk'),
      checked: value && value.id === 0
    });

    return data;
  }, [dirs, value, filter, t]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <TreeSelect
      className="directory-selection"
      data={treeData}
      onChange={handleSelectedDirChange}
      texts={{
        placeholder: placeholder || t('Select directory')
      }}
    />
  );
};

export default DirectoryPicker;
