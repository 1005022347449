import global from './global.json';
import messages from './messages.json';
import navigation from './navigation.json';
import users from './users.json';
import validation from './validation.json';
import myDisk from './myDisk.json';

export default {
  messages,
  navigation,
  global,
  users,
  validation,
  myDisk
};
