import React, { useEffect, useState, useMemo } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { renderRoutes, RouteConfig } from 'react-router-config';
import { applyFilters, bootstrap, Filters } from './core';
import { configureStore, RootState } from './store';
import http, { injectStore } from './utils/http';
import {
  ScrollReset,
  LocaleUpdater,
  GoogleAnalytics,
  FixedLoader,
  PwaDynamicManifest,
  Compose
} from 'components';
import { generateRoutes } from './routes';
import { fetchUser } from './modules/auth/actions';
import { useAppSettings } from './modules/ui/hooks';
import configureTheme from './theme';
import FilePreviewProvider from './modules/files/providers/FilePreviewProvider';
import { DrawerContextProvider } from './views/MyDisk/DrawerContext';
import SnackBarsProvider from './components/Snackbar/SnackBarsProvider';
import DirectoryPickerProvider from './components/DirectoryPicker/DirectoryPickerProvider';

export const store = configureStore();
const history = createBrowserHistory();
injectStore(store);

interface RouterProps {
  routes: RouteConfig[];
}

const AppRouter: React.FC<RouterProps> = ({ routes }) => {
  const dispatch = useDispatch();
  const { accessToken, isUserLoading } = useSelector((state: RootState) => ({
    accessToken: state.auth.accessToken,
    isUserLoading: state.auth.isUserLoading
  }));

  useEffect(() => {
    if (accessToken) {
      dispatch(fetchUser.trigger());
    }
    // eslint-disable-next-line
  }, []);

  if (isUserLoading) {
    return <FixedLoader />;
  }

  return renderRoutes(routes);
};

const fetchConfig = async () => {
  return http.get('/system/config');
};

const App: React.FC = () => {
  const [routes, setRoutes] = useState();
  const { settings, setSettings } = useAppSettings();
  const { accessToken } = useSelector((state: RootState) => ({
    accessToken: state.auth.accessToken
  }));
  const appSettings = useSelector((state: RootState) => state.ui.app);
  const theme = useMemo(() => configureTheme(settings), [settings]);

  useEffect(() => {
    if (document && appSettings.webFavicon) {
      const icon = document.getElementById('app-favicon');
      if (icon) {
        icon.setAttribute('href', appSettings.webFavicon);
      }
    }
  }, [appSettings.webFavicon]);

  useEffect(() => {
    fetchConfig().then(async config => {
      // Bootstrap Application give opportunity for plugins to install
      console.log('BEFORE BOOTSTRAP');
      await bootstrap({ store });
      console.log('AFTER BOOTSTRAP');
      const extraRoutes = applyFilters(Filters.app.extraRoutes, []);
      const extraPublicRoutes = applyFilters(Filters.app.extraPublicRoutes, []);
      const routes = generateRoutes(config, extraRoutes, extraPublicRoutes);
      setSettings(config);
      setRoutes(routes);
    });

    // eslint-disable-next-line
  }, [accessToken]);

  const providers: React.ComponentType[] = applyFilters(
    Filters.app.providers,
    []
  );

  if (!routes) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <PwaDynamicManifest config={settings} />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Compose
          components={[
            FilePreviewProvider,
            DrawerContextProvider,
            DirectoryPickerProvider
          ]}>
          <Router history={history}>
            {providers.map((Component, index) => (
              <Component key={index} />
            ))}
            <ScrollReset />
            <LocaleUpdater />
            <GoogleAnalytics />
            <SnackBarsProvider>
              <AppRouter routes={routes} />
            </SnackBarsProvider>
          </Router>
        </Compose>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

const AppWithProviders = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default class AppWithErrorHandler extends React.Component {
  componentDidCatch(error: Error) {
    switch (error.name) {
      case 'ChunkLoadError':
        if (window) {
          // when chunk cannot be found (usually after deployment new version)
          // then refresh the page.
          window.location.reload();
        }
        break;
      default:
        throw error;
    }
  }

  render() {
    return <AppWithProviders />;
  }
}
