import React from 'react';
import clsx from 'clsx';
import { NavLink as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Button,
  Collapse,
  colors,
  ListItem,
  Typography
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'utils/translation';
import { Theme } from 'theme';
import { useQuery } from 'react-query';
import http from '../../../../../../utils/http';
import { Report } from '../../../../../../modules/reports/types';
import useRouter from '../../../../../../utils/useRouter';
import { ReactComponent as ReportsIcon } from '../../../../../../assets/icons/inventory.svg';
import { applyFilters, Filters } from '../../../../../../core';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    position: 'relative',
    color: colors.blueGrey[800],
    borderRadius: 0,
    padding: '12px 24px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  children: {
    padding: '8px 0'
  },
  reportItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 12px 4px 24px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    fontSize: '0.8em',
    fontWeight: 400
  },
  activeItem: {
    fontWeight: 800
  },
  expandIcon: {
    height: 24,
    width: 24,
    position: 'absolute',
    left: 0
  }
}));

export const ReportNav = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation('navigation');
  const router = useRouter();
  const isOpen = router.location.pathname.startsWith('/report');
  const [open, setOpen] = React.useState(isOpen);
  const { data = [], isLoading } = useQuery(
    `api_available_reports_${i18n.language}`,
    () => http.get<never, Array<Report>>('/api/reports')
  );

  if (isLoading || data.length === 0) {
    return null;
  }

  const navLabel = applyFilters(Filters.navbar.reportsTitle, 'Reports');

  return (
    <>
      <ListItem className={clsx(classes.item)} disableGutters>
        <Button
          className={classes.button}
          onClick={() => setOpen(prev => !prev)}>
          {open ? (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ChevronRightIcon className={classes.expandIcon} color="inherit" />
          )}
          <ReportsIcon className={classes.icon} />
          {t(navLabel)}
        </Button>
      </ListItem>
      <Collapse in={open}>
        <Box className={classes.children}>
          {data.map(report => (
            <Typography
              key={report.id}
              // @ts-ignore
              component={RouterLink}
              to={`/report/${report.id}`}
              variant="button"
              className={clsx(classes.reportItem, {
                [classes.activeItem]: router.location.pathname.startsWith(
                  `/report/${report.id}`
                )
              })}>
              <ListIcon />
              {report.name}
            </Typography>
          ))}
        </Box>
      </Collapse>
    </>
  );
};
