import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { Toolbar, useMediaQuery } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FixedLoader, Page } from '../../components';
import { useTranslation } from 'utils/translation';
import { Breadcrumbs, Drawer } from './components';
import {
  useDirs,
  useDirsActions,
  useSingleDir
} from '../../modules/dirs/hooks';
import useRouter from '../../utils/useRouter';
import ResultsView from './components/Results';
import { EmptyDir } from '../../modules/dirs/components/EmptyDir';
import {
  isDir,
  mapResources,
  Resource,
  RESOURCE_TYPE
} from '../../modules/resources';
import {
  useCurrentDir,
  useResultsViewMode,
  useUIError
} from '../../modules/ui/hooks';
import { ViewSwitcher } from './components/ViewSwitcher/ViewSwitcher';
import { useFileList, useFilesActions } from '../../modules/files/hooks';
import { ViewMode } from '../../modules/ui/types';
import useDiskStyles from './MyDisk.styles';
import { useLazyLoading } from '../../utils/use-lazy-loading';
import ResourceModals from './components/ResourceModals';
import { Theme } from '../../theme';
import { UIErrorView } from '../../modules/ui/components/UIError';
import MyDiskProviders from './MyDiskProviders';
import { useSortableDiskContext } from './utils/sortable.context';
import { useDrawerContext } from './DrawerContext';

const MyDisk = () => {
  const isDesktop = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const { t } = useTranslation('navigation');
  const { fetchDirs, readDir } = useDirsActions();
  const { fetchFiles } = useFilesActions();
  const { setCurrent, reset } = useCurrentDir();
  const { viewMode, changeViewMode } = useResultsViewMode();
  const uiError = useUIError();

  const router = useRouter();
  const params = router.match.params as { id: number };
  const dirId = params.id;
  const { dir, isFetching: isSingleFetching } = useSingleDir(dirId);
  const { dirs, isFetching } = useDirs(dirId);
  const { files, isFetching: filesIsFetching } = useFileList(dirId);
  const isLoading = isFetching || filesIsFetching || isSingleFetching;
  const resources = mapResources({ dirs, files });
  const {
    selection: drawerSelection,
    setSelection: setDrawerSelection
  } = useDrawerContext();
  const { classes, drawerOptions } = useDiskStyles({
    isOpen: !!drawerSelection
  });
  const { sort } = useSortableDiskContext();
  const { increasePage, items } = useLazyLoading<Resource>(
    {
      items: sort(resources)
    },
    [dirId]
  );

  useEffect(() => {
    if (dirId) {
      fetchDirs({
        parentId: dirId
      });
      readDir({
        id: dirId
      });
      setCurrent(dirId);
    } else {
      reset();
      fetchFiles();
      fetchDirs();
    }

    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, [dirId]);

  useLayoutEffect(() => {
    if (dir && isDesktop && viewMode !== ViewMode.Contextual) {
      setDrawerSelection({
        ...dir,
        type: RESOURCE_TYPE.DIRECTORY
      });
    }
  }, [dir, isDesktop, viewMode, setDrawerSelection]);

  const handleItemClick = (item: Resource) => {
    if (viewMode !== ViewMode.Contextual) {
      setDrawerSelection(item);
    }

    if (isDir(item)) {
      readDir({
        id: item.id
      });
    }
  };

  const handleYReachEnd = () => {
    increasePage();
  };

  const handleDrawerClose = useCallback(() => {
    setDrawerSelection(null);
  }, [setDrawerSelection]);

  const getResources = () => {
    if (viewMode === ViewMode.Table) {
      return resources;
    }

    return items;
  };

  const renderContent = () => {
    if (uiError) {
      return <UIErrorView error={uiError} />;
    }

    if (!isLoading && resources.length === 0) {
      return <EmptyDir />;
    }

    if (resources.length > 0) {
      return (
        <ResultsView
          viewMode={viewMode}
          selectedResource={drawerSelection}
          resources={getResources()}
          onClick={handleItemClick}
        />
      );
    }

    return null;
  };

  return (
    <Page className={classes.root} title={t('My Disk')}>
      {isLoading && <FixedLoader />}
      <div className={classes.container}>
        <Toolbar className={classes.toolbar}>
          <Breadcrumbs />
          <ViewSwitcher onChange={changeViewMode} value={viewMode} />
        </Toolbar>
        <div className={classes.scrollbarContainer}>
          <PerfectScrollbar
            options={{
              suppressScrollX: true,
              wheelSpeed: 3
            }}
            onYReachEnd={handleYReachEnd}>
            <div className={classes.content}>{renderContent()}</div>
          </PerfectScrollbar>
        </div>
      </div>
      <ResourceModals />
      <Drawer
        resource={drawerSelection}
        open={!!drawerSelection}
        onClose={handleDrawerClose}
        options={drawerOptions}
      />
    </Page>
  );
};

export default () => (
  <MyDiskProviders>
    <MyDisk />
  </MyDiskProviders>
);
