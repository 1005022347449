import React, { useEffect, useRef, useState } from 'react';
import { TextField, Tooltip } from '@material-ui/core';
import { useTranslation } from 'utils/translation';

interface Props {
  value: string;
}

const CopyableInput: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    let timeout: any;
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [copied]);

  const handleCopyClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      setCopied(true);
    }
  };

  return (
    <Tooltip
      title={t('Copied to clipboard!') as string}
      placement="top-end"
      open={copied}
      disableFocusListener
      disableHoverListener
      disableTouchListener>
      <TextField
        onClick={handleCopyClipboard}
        fullWidth
        variant="outlined"
        inputProps={{ readOnly: true, ref: inputRef }}
        value={value}
      />
    </Tooltip>
  );
};

export default CopyableInput;
