import { takeLatest, put } from 'redux-saga/effects';
import { login, logout, fetchUser, refreshToken } from './actions';
import { createApiHandler } from '../@common/sagas/create-api-handler';
import { login as loginHandler, fetchCurrentUser } from './service/api';
import { LoginPayload } from './index';
import { removeCredentials, storeCredentials } from './service/storage';
import { queryCache } from 'react-query';

const handleLogin = createApiHandler({
  routine: login,
  provider: (payload: LoginPayload) => {
    return loginHandler(payload);
  }
});

function* handleLoginSuccess(action: any) {
  const { access_token, refresh_token } = action.payload;

  yield storeCredentials({
    accessToken: access_token,
    refreshToken: refresh_token
  });

  yield put(fetchUser.trigger());
}

function* handleRefreshTokenSuccess(action: any) {
  const { access_token, refresh_token } = action.payload;

  yield storeCredentials({
    accessToken: access_token,
    refreshToken: refresh_token
  });
}

function* handleLogout() {
  yield removeCredentials();
  yield queryCache.clear();
}

const handleFetchUser = createApiHandler({
  routine: fetchUser,
  provider: fetchCurrentUser
});

export default function*() {
  yield takeLatest(login.TRIGGER, handleLogin);
  yield takeLatest(login.SUCCESS, handleLoginSuccess);
  yield takeLatest(refreshToken.SUCCESS, handleRefreshTokenSuccess);
  yield takeLatest(logout.TRIGGER, handleLogout);
  yield takeLatest(fetchUser.TRIGGER, handleFetchUser);
}
