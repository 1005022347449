import React, { useEffect, useState } from 'react';
import { Dir } from '../../../../../modules/dirs';
import {
  createStyles,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Select } from 'components';
import {
  useGroups,
  useGroupsActions,
  useIsGroupsLoading
} from '../../../../../modules/user-groups/hooks';
import { Skeleton } from '@material-ui/lab';
import {
  useDirGroupsAccess,
  useGroupAccessActions
} from '../../../../../modules/dirs-access/hooks';
import { Group } from '../../../../../modules/user-groups';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTranslation } from 'react-i18next';

interface Props {
  dir: Dir;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: '35vh',
      padding: theme.spacing(2, 0)
    },
    table: {
      margin: theme.spacing(2, 0)
    },
    scrollbarContent: {
      maxHeight: '60vh'
    }
  })
);

const GroupsManagement: React.FC<Props> = ({ dir }) => {
  const classes = useStyles();
  const { t } = useTranslation('users');
  const [value, setValue] = useState();
  const { fetch } = useGroupsActions();
  const isFetching = useIsGroupsLoading();
  const usersGroups = useGroups();
  const actions = useGroupAccessActions();
  const assignedGroups = useDirGroupsAccess(dir);
  const [sortDir, setSortDir] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    fetch();
    actions.fetch({
      dirId: dir.id
    });
    // eslint-disable-next-line
  }, []);

  const handleSortChange = () => {
    const nextSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    setSortDir(nextSortDir);
  };

  const handleAddGroup = (id: number) => {
    const group = usersGroups.find(item => item.id === id);

    actions.create({
      dirId: dir.id,
      groupId: id,
      group
    });
  };

  const handleDeleteGroup = (group: Group) => {
    actions.remove({
      dirId: dir.id,
      groupId: group.id
    });
  };

  const handleChange = (item: any) => {
    if (item) {
      setValue('');
      handleAddGroup(item.value);
    }
  };

  const assignedGroupsItems = Array.from<Group>(
    assignedGroups.groups || []
  ).sort((a, b) => {
    if (a.name === b.name) {
      return 0;
    }

    const val = sortDir === 'asc' ? 1 : -1;
    return a.name < b.name ? val * -1 : val * 1;
  });
  const assignedGroupIds = assignedGroupsItems.map(item => item.id);
  const hasGroups = assignedGroupIds.length > 0;

  const options = usersGroups
    .filter(item => !assignedGroupIds.includes(item.id))
    .map(group => ({
      value: group.id,
      label: group.name
    }));

  if (isFetching || assignedGroups.isFetching) {
    return <Skeleton width="100%" height={48} />;
  }

  return (
    <div className={classes.root}>
      <Select
        options={options}
        placeholder={t('Search groups')}
        noOptionsMessage={() => t('No groups found')}
        value={value}
        onChange={handleChange}
      />
      <PerfectScrollbar className={classes.scrollbarContent}>
        {hasGroups && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <TableSortLabel
                    active={true}
                    direction={sortDir}
                    onClick={handleSortChange}>
                    {t('Group')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignedGroupsItems.map(group => (
                <TableRow key={group.id}>
                  <TableCell>{group.name}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteGroup(group)}>
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </PerfectScrollbar>
    </div>
  );
};

export { GroupsManagement };
