import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CardMedia,
  colors,
  IconButton,
  Typography
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { Theme } from 'theme';
import {
  ContextMenuState,
  isDir,
  isFile,
  Resource,
  useResourceModal
} from '../../../../modules/resources';
import {
  useFilesActions,
  useFileThumbnail
} from '../../../../modules/files/hooks';
import { FileIcon, LazyImage } from '../../../../components';
import { useUserIsSuperAdmin } from '../../../../modules/auth/hooks';
import { ResourceModal } from '../../../../modules/ui';
import ResourceIcon from './ResourceIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    color: colors.blueGrey[500]
  },
  media: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: 132,
    '& > img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      userSelect: 'none'
    }
  },
  placeholder: {
    height: 132,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  icon: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    fontSize: theme.spacing(8)
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  label: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: 'none'
  },
  selected: {
    backgroundColor: colors.blueGrey[50]
  },
  drawerSelected: {
    color: colors.blueGrey[700],
    backgroundColor: colors.blueGrey[50],
    '& $label': {
      fontWeight: 'bold'
    }
  },
  lockButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: 0
  },
  lockIcon: {
    fontSize: theme.spacing(2)
  }
}));

interface CardProps {
  resource: Resource;
  isSelected?: boolean;
  onDoubleClick(): void;
  onClick(): void;
  onContextMenu(data: ContextMenuState): void;
}

const GridItem = (props: CardProps | any) => {
  const {
    resource,
    onClick,
    onDoubleClick,
    onContextMenu,
    className,
    selectedResource,
    isSelected = false,
    ...rest
  } = props;
  const classes = useStyles();
  const { getThumb } = useFilesActions();
  const thumbnail = useFileThumbnail(resource.id);
  const { openModal } = useResourceModal();
  const isSuperAdmin = useUserIsSuperAdmin();

  const [hasThumb, setHasThumb] = useState<boolean>(
    isFile(resource) && !!thumbnail
  );

  useEffect(() => {
    if (isFile(resource)) {
      getThumb(resource);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHasThumb(isFile(resource) && !!thumbnail);
  }, [thumbnail, resource]);

  const onContextMenuClick = (event: MouseEvent) => {
    event.preventDefault();
    onContextMenu({
      resource: resource,
      x: event.clientX,
      y: event.clientY
    });
  };

  const handleLockIconClick = () => {
    if (isDir(resource)) {
      openModal(ResourceModal.Permissions, resource);
    }
  };

  const isCurrentSelected = () =>
    selectedResource &&
    selectedResource.type === resource.type &&
    selectedResource.id === resource.id;

  return (
    <Card
      {...rest}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onContextMenu={onContextMenuClick}
      className={clsx(classes.root, className, {
        [classes.selected]: isSelected,
        [classes.drawerSelected]: isCurrentSelected()
      })}>
      {isSuperAdmin && isDir(resource) && false === resource.isPublic && (
        <IconButton
          disableRipple
          className={classes.lockButton}
          onClick={handleLockIconClick}>
          <LockIcon className={classes.lockIcon} />
        </IconButton>
      )}
      {hasThumb ? (
        <CardMedia className={classes.media}>
          <LazyImage
            src={thumbnail}
            placeholder={({ ref }) => (
              <div ref={ref} className={classes.placeholder}>
                <FileIcon className={classes.icon} type={resource.fileType} />
              </div>
            )}
            error={() => (
              <div className={classes.placeholder}>
                <FileIcon className={classes.icon} type={resource.fileType} />
              </div>
            )}
          />
        </CardMedia>
      ) : (
        <div className={classes.placeholder}>
          {isDir(resource) && (
            <ResourceIcon className={classes.icon} item={resource} />
          )}
          {isFile(resource) && (
            <FileIcon className={classes.icon} type={resource.fileType} />
          )}
        </div>
      )}
      <CardContent className={classes.content}>
        <Typography variant="h6" className={classes.label}>
          {isDir(resource) && resource.title}
          {isFile(resource) && resource.filename}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default GridItem;
