import React from 'react';
import { Field as FieldType } from '..';

type Values = {
  fields: FieldType[];
};

const shouldBeVisible = (condition: string, values: Values) => {
  if (!condition) {
    return true;
  }

  const [name, operator, value] = condition.split(':');
  const relatedField = Array.from<FieldType>(values.fields || []).find(
    (item: any) => item.name === name
  );

  if (!relatedField) {
    console.warn('Related field not found: ', name);
    return true;
  }

  switch (operator) {
    case 'eq': {
      return relatedField.value === value;
    }
    case 'neq': {
      return relatedField.value !== value;
    }
    case 'in': {
      if (typeof relatedField.value === 'string') {
        return Array.from(value.split(',') || []).includes(relatedField.value);
      }

      break;
    }
    default: {
      throw new Error(`Unsupported operator: ${operator}`);
    }
  }
};

const ConditionalFieldChecker: React.FC<any> = ({
  values,
  field,
  children
}) => {
  const { condition } = field.settings;
  const isVisible = React.useMemo(() => shouldBeVisible(condition, values), [
    values,
    condition
  ]);

  if (!isVisible) {
    return null;
  }

  return children;
};

export default ConditionalFieldChecker;
