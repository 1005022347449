import React from 'react';
import { useTranslation } from 'utils/translation';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Gateway } from 'react-gateway';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import EditIcon from '@material-ui/icons/Edit';
import InputIcon from '@material-ui/icons/Input';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import GetAppIcon from '@material-ui/icons/GetApp';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import CloseIcon from '@material-ui/icons/Close';
import LayersIcon from '@material-ui/icons/Layers';
import ShareIcon from '@material-ui/icons/Share';
import { isDir, isFile, Resource } from '../../../../modules/resources';
import { useIsResourceInBasket } from '../../../../modules/basket/hooks';
import {
  useUserIsAdmin,
  useUserIsSuperAdmin
} from '../../../../modules/auth/hooks';
import { useResultsViewMode } from '../../../../modules/ui/hooks';
import { useDrawerContext } from '../../DrawerContext';
import { ViewMode } from '../../../../modules/ui/types';

interface Props {
  resource: Resource;
  x: number;
  y: number;
  onClose(): void;
  onRenameClick(resource: Resource): void;
  onMoveClick(resource: Resource): void;
  onDeleteClick(resource: Resource): void;
  onPermissionsClick(resource: Resource): void;
  onIntelligentLayerClick(resource: Resource): void;
  onDownloadClick(resource: Resource): void;
  onAddToBasketClick(resource: Resource): void;
  onRemoveFromBasketClick(resource: Resource): void;
  onShareClick(resource: Resource): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  container: {
    position: 'fixed',
    zIndex: 3000,
    backgroundColor: theme.palette.white,
    '& svg': {
      marginRight: theme.spacing(2)
    }
  },
  card: {
    minWidth: '200px'
  },
  item: {
    color: theme.palette.text.primary
  },
  icon: {
    color: theme.palette.text.secondary
  }
}));

const ContextMenu: React.FC<Props> = ({
  resource,
  x,
  y,
  onClose,
  onRenameClick,
  onMoveClick,
  onDeleteClick,
  onPermissionsClick,
  onIntelligentLayerClick,
  onDownloadClick,
  onAddToBasketClick,
  onRemoveFromBasketClick,
  onShareClick
}) => {
  const classes = useStyles();
  const { t } = useTranslation('myDisk');
  const isAdmin = useUserIsAdmin();
  const isSuperAdmin = useUserIsSuperAdmin();
  const { viewMode } = useResultsViewMode();
  const { setSelection } = useDrawerContext();

  let translateX = '0';
  let translateY = '0';
  const heightBorder = window.innerHeight / 2;
  const widthBorder = window.innerWidth / 2;

  if (x > widthBorder) {
    translateX = '-100%';
  }

  if (y > heightBorder) {
    translateY = '-100%';
  }

  const isInBasket = useIsResourceInBasket(resource);

  return (
    <Gateway into="footer">
      <div className={classes.root}>
        <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown">
          <div
            style={{
              top: y,
              left: x,
              transform: `translate(${translateX}, ${translateY})`
            }}
            onClick={onClose}
            className={classes.container}>
            <Card className={classes.card}>
              {isAdmin && (
                <>
                  <MenuItem
                    className={classes.item}
                    onClick={() => onRenameClick(resource)}>
                    <EditIcon className={classes.icon} />
                    {t('Rename')}
                  </MenuItem>
                  <MenuItem
                    className={classes.item}
                    onClick={() => onMoveClick(resource)}>
                    <InputIcon className={classes.icon} />
                    {t('Move')}
                  </MenuItem>
                  <MenuItem
                    className={classes.item}
                    onClick={() => onDeleteClick(resource)}>
                    <DeleteIcon className={classes.icon} />
                    {t('Delete')}
                  </MenuItem>
                  <Divider />
                </>
              )}
              {isDir(resource) && isSuperAdmin && (
                <MenuItem
                  className={classes.item}
                  onClick={() => onPermissionsClick(resource)}>
                  <AccessibilityNewIcon className={classes.icon} />
                  {t('Permissions')}
                </MenuItem>
              )}
              {isAdmin && (
                <>
                  <MenuItem
                    className={classes.item}
                    onClick={() => onIntelligentLayerClick(resource)}>
                    <LayersIcon className={classes.icon} />
                    {t('Intelligence Layer')}
                  </MenuItem>
                  <Divider />
                </>
              )}
              <MenuItem
                className={classes.item}
                onClick={() => onDownloadClick(resource)}>
                <GetAppIcon className={classes.icon} />
                {t('Download')}
              </MenuItem>
              {isInBasket && (
                <MenuItem
                  className={classes.item}
                  onClick={() => onRemoveFromBasketClick(resource)}>
                  <CloseIcon className={classes.icon} />
                  {t('Remove From Collection')}
                </MenuItem>
              )}
              {!isInBasket && (
                <MenuItem
                  className={classes.item}
                  onClick={() => onAddToBasketClick(resource)}>
                  <InboxIcon className={classes.icon} />
                  {t('Add To Collection')}
                </MenuItem>
              )}

              {isFile(resource) && (
                <MenuItem
                  className={classes.item}
                  onClick={() => onShareClick(resource)}>
                  <ShareIcon className={classes.icon} />
                  {t('Share')}
                </MenuItem>
              )}
              {viewMode === ViewMode.Contextual && (
                <MenuItem
                  className={classes.item}
                  onClick={() => setSelection(resource)}>
                  <InfoIcon className={classes.icon} />
                  {t(isDir(resource) ? 'Directory info' : 'File info')}
                </MenuItem>
              )}
            </Card>
          </div>
        </ClickAwayListener>
      </div>
    </Gateway>
  );
};

export default ContextMenu;
