import React from 'react';
import { useUiModal } from '../../../../modules/ui/hooks';
import { CREATE_DIRECTORY_MODAL_ID } from '../../../../modules/ui';
import { Box, Button, Divider, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'utils/translation';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { applyFilters } from '../../../../core';

type ObjectAction = {
  id: string;
  icon: React.ComponentType<{ className: string }>;
  action(): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    minWidth: theme.spacing(27),
    minHeight: theme.spacing(4),
    padding: theme.spacing(1, 4)
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const ObjectTypeActions = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { show } = useUiModal();
  const classes = useStyles();
  const { t } = useTranslation(['navigation', 'global']);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleCreateDirClick = () => {
    show(CREATE_DIRECTORY_MODAL_ID);
    handleClose();
  };

  const handleNewUploadClick = () => {
    const input = document.getElementById('file-upload-input');
    if (input) {
      input.click();
    }
    handleClose();
  };

  const standardActions = [
    {
      id: 'New folder',
      icon: CreateNewFolderIcon,
      action: handleCreateDirClick
    },
    {
      id: 'New upload',
      icon: CloudUploadIcon,
      action: handleNewUploadClick
    }
  ];

  const objectTypeActions = applyFilters<ObjectAction[]>(
    'contextual_view_object_type_actions',
    []
  );

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleClick}>
        <AddIcon />
        {t('global:Add')}
      </Button>
      <Menu
        id="navbar-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        {objectTypeActions.map(({ id, icon: Icon, action }) => (
          <MenuItem
            className={classes.menuItem}
            key={id}
            onClick={() => {
              action && action();
              handleClose();
            }}>
            <Icon className={classes.icon} />
            {t(id)}
          </MenuItem>
        ))}
        {objectTypeActions.length > 0 && (
          <Box my={1} mx={4}>
            <Divider />
          </Box>
        )}
        {standardActions.map(({ id, icon: Icon, action }) => (
          <MenuItem
            className={classes.menuItem}
            key={id}
            onClick={() => {
              action && action();
              handleClose();
            }}>
            <Icon className={classes.icon} />
            {t(id)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ObjectTypeActions;
