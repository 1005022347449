import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Button, colors, ListItem, Badge } from '@material-ui/core';
import { ReactComponent as AssigmentReturned } from '../../../../../../assets/icons/assigment-returned.svg';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'utils/translation';
import { Theme } from 'theme';
import {
  useBasketActions,
  useBasketItems
} from '../../../../../../modules/basket/hooks';
import { useUiModal } from '../../../../../../modules/ui/hooks';
import { BASKET_VIEW_MODAL_ID } from '../../../../../../modules/ui';

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    borderRadius: 0,
    padding: '12px 24px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  icon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  badge: {
    display: 'flex',
    flex: 1,
    '& span': {
      margin: theme.spacing(1.5)
    }
  }
}));

export const BasketNavItem = () => {
  const classes = useStyles();
  const { t } = useTranslation('navigation');
  const { fetchBasket } = useBasketActions();
  const { files, directories } = useBasketItems();
  const { show } = useUiModal();

  useEffect(() => {
    fetchBasket();
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    show(BASKET_VIEW_MODAL_ID);
  };

  const itemsCount = files.length + directories.length;

  return (
    <>
      <ListItem className={clsx(classes.item)} disableGutters>
        <Button className={classes.button} onClick={handleClick}>
          <Badge
            badgeContent={itemsCount}
            max={99}
            color="primary"
            component="div"
            className={classes.badge}>
            <AssigmentReturned className={classes.icon} />
            {t('Basket')}
          </Badge>
        </Button>
      </ListItem>
    </>
  );
};
