import http from 'utils/http';
import config from 'config/api';

type LoginArgs = {
  email: string;
  password: string;
};

export function login({ email, password }: LoginArgs) {
  return http.post('oauth/v2/token', {
    username: email,
    password: password,
    client_id: config.clientId,
    client_secret: config.clientSecret,
    grant_type: 'password'
  });
}

export function fetchCurrentUser() {
  return http.get('api/user/me');
}

export function requestPasswordReset({ email }: { email: string }) {
  return http.post('auth/password/reset', {
    email
  });
}

export function verifyResetPasswordToken({ token }: { token: string }) {
  return http.post('auth/password/verify-token', {
    token
  });
}

export function changePasswordWithToken({
  token,
  password
}: {
  token: string;
  password: string;
}) {
  return http.post('auth/password/change', {
    token,
    password
  });
}
