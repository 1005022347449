import { Action, createType } from '../../@common/actions/action';
import { handleActions } from 'redux-actions';
import { APP_SET_SETTINGS } from '../constants/settings';
import { SettingsPayload } from '../actions';

interface State {
  [key: string]: string;
}

const initialState: State = {};

const SET_SETTINGS_TYPE = createType(APP_SET_SETTINGS);

export const appSettingsReducer = handleActions(
  {
    [SET_SETTINGS_TYPE]: (state, action: Action<SettingsPayload>) => {
      const settings = action.payload;
      return {
        ...state,
        ...settings
      };
    }
  },
  initialState
);
