import http from 'utils/http';
import invariant from 'invariant';
import { CreateGroupPayload, DeleteGroupPayload, Group } from '../index';

const rootUrl = 'api/group';

export function fetchGroups() {
  return http.get(rootUrl);
}

export function createGroup(payload: CreateGroupPayload) {
  return http.post(rootUrl, payload);
}

export function updateGroup(group: Group) {
  invariant(group.id, 'Group id is required to perform update call');

  return http.put(`${rootUrl}/${group.id}`, group);
}

export function deleteGroup({ id }: DeleteGroupPayload) {
  invariant(id, 'Group id is required to perform delete call');

  return http.delete(`${rootUrl}/${id}`);
}
