import { createMuiTheme } from '@material-ui/core';

import defaultPalette from './palette';
import typography from './typography';
import overrides from './overrides';

export type Theme = ReturnType<typeof configureTheme>;

const configureTheme = (settings: any) => {
  const palette = defaultPalette;

  if (settings.webColor) {
    palette.primary = {
      ...palette.primary,
      dark: settings.webColor,
      main: settings.webColor,
      light: settings.webColor
    };
    palette.secondary = {
      ...palette.secondary,
      dark: settings.webColor,
      main: settings.webColor,
      light: settings.webColor
    };
  }

  return createMuiTheme({
    palette,
    typography,
    overrides
  });
};

export default configureTheme;
