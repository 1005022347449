import React from 'react';
import { useTranslation } from 'utils/translation';
import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import addFileImage from 'assets/images/add-file.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(80),
    margin: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
  image: {
    maxWidth: theme.spacing(30)
  },
  title: {
    margin: theme.spacing(2, 0)
  }
}));

const EmptyDir: React.FC = () => {
  const { t } = useTranslation('myDisk');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img className={classes.image} alt="" src={addFileImage} />
      <Typography className={classes.title} variant="h3">
        {t('No files or folders have been uploaded yet')}
      </Typography>
    </div>
  );
};

export { EmptyDir };
