import React from 'react';
import clsx from 'clsx';
import DropdownTreeSelect, {
  DropdownTreeSelectProps
} from 'react-dropdown-tree-select';
import { makeStyles } from '@material-ui/styles';
import './TreeSelect.scss';

const useStyles = makeStyles(() => ({
  root: {
    '.dropdown-content': {
      maxHeight: '50vh',
      overflowY: 'scroll'
    }
  }
}));

interface Props {
  //
}

const TreeSelect: React.FC<Props & DropdownTreeSelectProps> = ({
  data,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <DropdownTreeSelect
      className={clsx(classes.root, 'tree-select-component', className)}
      data={data}
      {...props}
    />
  );
};

TreeSelect.defaultProps = {
  mode: 'radioSelect'
};

export default TreeSelect;
