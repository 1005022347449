import React, { useEffect, useRef } from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormikBag, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'utils/translation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    marginTop: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0
  }
}));

interface FormValues {
  currentPassword?: string;
  password: string;
  passwordConfirmation: string;
}

type FormBag = FormikBag<OtherProps, FormValues>;

interface OtherProps {
  onCancel: () => void;
  onSubmit: (values: FormValues, bag: FormBag) => void;
  initialValues?: Partial<FormValues>;
  currentPasswordRequired?: boolean;
}

const BasePasswordChangeForm = (
  props: OtherProps & FormikProps<FormValues>
) => {
  const classes = useStyles();
  const { t } = useTranslation(['users', 'validation', 'global']);
  const passwordRef = useRef<HTMLInputElement>(null);
  const currentPassRef = useRef<HTMLInputElement>(null);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,

    onCancel,
    currentPasswordRequired
  } = props;

  useEffect(() => {
    if (currentPasswordRequired && currentPassRef.current) {
      currentPassRef.current.focus();
    } else if (passwordRef.current) {
      // @ts-ignore
      passwordRef.current.focus();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid className={classes.container} container spacing={2}>
          {currentPasswordRequired && (
            <Grid item xs={12}>
              <FormControl
                error={Boolean(
                  touched.currentPassword && errors.currentPassword
                )}
                fullWidth>
                <InputLabel>{t('Current password')}</InputLabel>
                <Input
                  name="currentPassword"
                  type="password"
                  value={values.currentPassword}
                  onChange={handleChange}
                  inputRef={currentPassRef}
                />
                {Boolean(touched.currentPassword && errors.currentPassword) && (
                  <FormHelperText>
                    {t(`validation:${errors.currentPassword}`)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl
              error={Boolean(touched.password && errors.password)}
              fullWidth>
              <InputLabel>{t('New password')}</InputLabel>
              <Input
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                inputRef={passwordRef}
                autoComplete="off"
              />
              {Boolean(touched.password && errors.password) && (
                <FormHelperText>
                  {t(`validation:${errors.password}`)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid className={classes.container} container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              error={Boolean(
                touched.passwordConfirmation && errors.passwordConfirmation
              )}
              fullWidth>
              <InputLabel>{t('Confirm new password')}</InputLabel>
              <Input
                name="passwordConfirmation"
                type="password"
                value={values.passwordConfirmation}
                onChange={handleChange}
                inputRef={passwordRef}
                autoComplete="off"
              />
              {Boolean(
                touched.passwordConfirmation && errors.passwordConfirmation
              ) && (
                <FormHelperText>
                  {t(`validation:${errors.passwordConfirmation}`)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <CardActions className={classes.actions}>
          <Button onClick={onCancel} variant="contained">
            {t('Cancel')}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}>
            {isSubmitting ? <CircularProgress size={24} /> : t('Save')}
          </Button>
        </CardActions>
      </form>
    </div>
  );
};

const buildValidationSchema = ({ currentPasswordRequired }: OtherProps) => {
  const shape = {
    password: Yup.string()
      .required('Required')
      .min(4, 'Password is too short'),
    passwordConfirmation: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  };

  if (currentPasswordRequired) {
    // @ts-ignore
    shape.currentPassword = Yup.string().required('Required');
  }

  return Yup.object().shape(shape);
};

BasePasswordChangeForm.defaultProps = {
  currentPasswordRequired: false
};

export const PasswordChangeForm = withFormik<OtherProps, FormValues>({
  mapPropsToValues: () => {
    return {
      password: '',
      passwordConfirmation: '',
      currentPassword: ''
    };
  },
  validationSchema: buildValidationSchema,
  handleSubmit: (values, bag) => {
    const { onSubmit } = bag.props;
    onSubmit(values, bag);
  }
})(BasePasswordChangeForm);

export type Form = {
  Values: FormValues;
  Bag: FormBag;
};
