import React, { useCallback } from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  TableCell,
  TableRow,
  Theme,
  Typography,
  TableBody,
  Table
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Field, FieldArray, Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'utils/translation';
import Box from '@material-ui/core/Box';
import {
  Field as FieldType,
  FieldUpdatePayload
} from '../../../../../modules/intelligent-layer';
import { FormField } from 'modules/intelligent-layer/components/FormFields/FormField';
import get from 'lodash/get';
import { useIntelligenceFields } from '../../../../../modules/intelligent-layer/hooks';
import { useCreateNewObjectType } from '../../../../../modules/dirs/hooks';
import useRouter from '../../../../../utils/useRouter';
import { Loader } from '../../../../../components';

interface RowProps {
  field: FieldType & { choices?: any[] };
  name: string;
  error: string | null | undefined;
  choices?: any;
  onChange?: ((event: any) => void) | undefined;
}

const useFieldValidator = (field: FieldType) => {
  const { t } = useTranslation('myDisk');
  const { settings = {} } = field;

  return () => {
    if (settings.required) {
      return t('Required');
    }
  };
};

const FieldWrapper = (props: any) => {
  let settings = {
    options: props.choices.map((choice: string) => ({
      label: choice,
      value: choice
    })),
    ...props.settings
  };
  let disabled = false;

  return (
    <FormField
      errorMessage={props.error}
      form={props.form}
      type={props.fieldType}
      settings={settings}
      disabled={disabled}
      name={props.field.name}
      fieldName={props.fieldName}
      multiple={props.multiple}
      value={props.field.value}
      onChange={props.onChange || props.field.onChange}
    />
  );
};

const FormFieldRow: React.FC<RowProps> = ({ field, name, error, onChange }) => {
  const validator = useFieldValidator(field);

  const label = (
    <Typography>{get(field, 'settings.label', field.name)}</Typography>
  );

  const formField = (
    <Field
      error={error}
      validate={validator}
      fieldType={field.type}
      fieldName={field.name}
      multiple={field.multiple}
      settings={field.settings}
      choices={field.choices}
      onChange={onChange}
      name={name}
      component={FieldWrapper}
    />
  );

  return (
    <TableRow>
      <TableCell padding="none" style={{ borderBottom: 'none' }}>
        <Box my={1}>
          <Box mb={1}>{label}</Box>
          <Box>{formField}</Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0
  }
}));

export interface FormValues {
  title: string;
  cover?: File;
  fields: FieldType[];
}

export interface SubmitPayload {
  title: string;
  cover?: File;
  fields: {
    [key: string]: any;
  };
}

interface OtherProps {
  onCancel: () => void;
  initialValues?: Partial<FormValues>;
}

const buildValidationSchema = () => {
  return Yup.object().shape({
    title: Yup.string().required('Required'),
    cover: Yup.string().nullable()
  });
};

const BaseObjectTypeForm = (props: OtherProps) => {
  const { onCancel, initialValues = {} } = props;
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation(['global', 'validation']);
  const { data = [], isSuccess } = useIntelligenceFields(['object-type']);
  const [mutate] = useCreateNewObjectType();

  const onSubmit = useCallback(
    async (values: SubmitPayload) => {
      const response = await mutate(values);
      onCancel();

      return response;
    },
    [mutate, onCancel]
  );

  const handleSubmit = React.useCallback(
    async (values: FormValues) => {
      const fieldsPayload: FieldUpdatePayload = {};

      values.fields.forEach(field => {
        fieldsPayload[field.name] = field.value;
      });

      const payload = {
        title: values.title,
        cover: values.cover,
        fields: fieldsPayload
      };

      const response = await onSubmit(payload);

      if (response && response.id) {
        router.history.push(`/directory/${response.id}`);
      }
    },
    [onSubmit, router]
  );

  if (!isSuccess) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <Formik
      validationSchema={buildValidationSchema}
      initialValues={{
        fields: data as FieldType[],
        title: initialValues.title || '',
        cover: undefined
      }}
      onSubmit={handleSubmit}
      render={({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleChange
      }) => (
        <Form>
          <Typography align="center" gutterBottom variant="h3">
            {t('Create Object Type')}
          </Typography>
          <Grid className={classes.container} container spacing={3}>
            <Grid item xs={12}>
              <FormControl error={!!(errors.title && touched.title)} fullWidth>
                <InputLabel>{t('Object Type Title')}</InputLabel>
                <Input
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  autoComplete="off"
                />
                {errors.title && touched.title && (
                  <FormHelperText>
                    {t(`validation:${errors.title}`)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Table>
                <TableBody>
                  <FieldArray
                    name="fields"
                    render={() =>
                      values.fields.map((field, index) => (
                        <FormFieldRow
                          key={field.id}
                          field={field}
                          name={`fields.${index}.value`}
                          error={get(errors, `fields.${index}.value`)}
                        />
                      ))
                    }
                  />
                </TableBody>
              </Table>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <label htmlFor="contained-button-file">
                  <Input
                    style={{ display: 'none' }}
                    name="document"
                    id="contained-button-file"
                    type="file"
                    onChange={(event: any) => {
                      if (event.currentTarget && event.currentTarget.files) {
                        setFieldValue('cover', event.currentTarget.files[0]);
                      }
                    }}
                  />
                  <Box display="flex" flexDirection="column">
                    {values.cover && ((values.cover as unknown) as File).name && (
                      <Typography variant="caption" gutterBottom>
                        {((values.cover as unknown) as File).name}
                      </Typography>
                    )}
                    <Button variant="contained" component="span">
                      {t('Cover file')}
                    </Button>
                  </Box>
                </label>
                {errors.cover && (
                  <FormHelperText>
                    {t(`validation:${errors.cover}`)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <CardActions className={classes.actions}>
            <Button onClick={onCancel} variant="contained">
              {t('Cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isSubmitting}>
              {isSubmitting ? <CircularProgress size={24} /> : t('Create')}
            </Button>
          </CardActions>
        </Form>
      )}
    />
  );
};

export const ObjectTypeForm = BaseObjectTypeForm;
