import React, { useContext, createContext } from 'react';
import { Dir } from './index';

const DirContext = createContext<Dir | null>(null);

export const useDirContext = () => {
  return useContext(DirContext);
};

export const DirContextProvider: React.FC<{ dir: Dir }> = ({
  dir,
  children
}) => {
  return <DirContext.Provider value={dir}>{children}</DirContext.Provider>;
};
