import React from 'react';
import { FieldProps } from './types';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Box
} from '@material-ui/core';
import { applyFilters } from 'core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'utils/translation';

const useStyles = makeStyles(() => ({
  control: {
    maxWidth: '600px'
  }
}));

const resolveValue = (value: any, defaultValue: any) => {
  if (value && typeof value === 'object' && 'value' in value) {
    return value.value;
  }

  if (Array.isArray(value)) {
    return value.map(i => i.toString());
  }

  return (value || defaultValue || '').toString();
};

const SelectField: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  fieldName,
  multiple,
  onChange,
  disabled,
  form,
  settings = {}
}) => {
  const { t } = useTranslation();
  const customInputRef = React.useRef<HTMLInputElement | null>();
  const classes = useStyles();
  const options = applyFilters(
    `intelligence_field_options_${fieldName}`,
    Array.from(settings.options || []),
    { form }
  ) as any[];
  const defaultValue = multiple ? [] : '';
  const supportsCustomChoice = !!settings.allowCustomChoice;
  const fieldValue = resolveValue(value, defaultValue);
  const hasCustomValue = React.useMemo(() => {
    if (!supportsCustomChoice) {
      return false;
    }

    return !options.find(
      item => item.value.toString() === fieldValue.toString()
    );
  }, [supportsCustomChoice, options, fieldValue]);
  const [customChoice, setCustomChoice] = React.useState(hasCustomValue);

  React.useEffect(() => {
    if (
      fieldValue &&
      !supportsCustomChoice &&
      !multiple &&
      !options.find(item => item.value.toString() === fieldValue.toString())
    ) {
      console.log('Value not found in options... ', fieldValue, options);
      form.setFieldValue(name, null, false);
    }
  }, [form, multiple, name, options, fieldValue, supportsCustomChoice]);

  React.useEffect(() => {
    if (hasCustomValue) {
      return;
    }

    if (customChoice && customInputRef.current) {
      customInputRef.current.focus();
    }
  }, [customChoice, hasCustomValue]);

  const handleRegularChoiceClick = () => {
    setCustomChoice(false);
  };

  const handleCustomChoiceClick = () => {
    setCustomChoice(true);
  };

  return (
    <FormControl
      className={classes.control}
      fullWidth
      variant="outlined"
      error={!!errorMessage}>
      {errorMessage && <InputLabel>{errorMessage}</InputLabel>}
      <Select
        disabled={disabled || options.length === 0}
        multiple={multiple}
        name={name}
        value={fieldValue}
        onChange={onChange}>
        {options.map((option, index) => (
          <MenuItem
            disabled={option.disabled}
            key={index}
            value={(option.value || '').toString()}
            onClickCapture={handleRegularChoiceClick}>
            {option.label}
          </MenuItem>
        ))}
        {supportsCustomChoice && (
          <MenuItem
            value={hasCustomValue ? fieldValue : ''}
            onClickCapture={handleCustomChoiceClick}>
            {t('intelligence_layer_select_field_custom_choice')}
          </MenuItem>
        )}
      </Select>
      {customChoice && (
        <Box my={1}>
          <TextField
            inputRef={customInputRef}
            error={!!errorMessage}
            helperText={errorMessage}
            variant="outlined"
            fullWidth
            value={fieldValue}
            name={name}
            onChange={onChange}
            autoComplete="off"
          />
        </Box>
      )}
    </FormControl>
  );
};

export { SelectField };
