import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'intersection-observer';

import './assets/scss/index.scss';

import './mixins/moment';
import './mixins/validate';
import './utils/translation';

import React from 'react';
import { render } from 'react-dom';
import App, { store } from './App';
import { AppContainer } from 'react-hot-loader';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
import { bootstrap, reset } from './core';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://488c3c5fc139478b8fd4db974a10e4ed@sentry.io/1833241'
  });
}

const renderApp = (Application: React.ComponentType) =>
  render(
    <AppContainer>
      <Application />
    </AppContainer>,
    document.getElementById('root')
  );

// @ts-ignore
if (process.env.NODE_ENV !== 'production' && module.hot) {
  // @ts-ignore
  module.hot.accept('./App', async () => {
    reset();
    await bootstrap({ store }, true);
    renderApp(require('./App').default);
  });
}

renderApp(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
