import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { selectActiveModal } from './selectors/modal';
import {
  selectCurrentDir,
  selectPerPage,
  selectUIError
} from './selectors/disk';
import {
  showModal,
  hideModal,
  setPerPage,
  setCurrentDir,
  resetCurrentDir,
  setSettings as setSettingsAction
} from './actions';
import { ViewMode } from './types';
import { useState } from 'react';
import { RootState } from '../../store';

export function useAppSettings() {
  const dispatch = useDispatch();
  const settings = useSelector(
    (state: RootState) => state.ui.app,
    shallowEqual
  );
  const setSettings = (settings: any) => dispatch(setSettingsAction(settings));

  return {
    settings,
    setSettings
  };
}

export function useUiModal() {
  const dispatch = useDispatch();
  const activeModal = useSelector(selectActiveModal, shallowEqual);
  const show = useCallback((id: string) => dispatch(showModal({ id })), [
    dispatch
  ]);
  const hide = useCallback(() => dispatch(hideModal()), [dispatch]);

  return {
    activeModal,
    show,
    hide
  };
}

export function useDiskPagination() {
  const dispatch = useDispatch();
  const set = (perPage: number) =>
    dispatch(
      setPerPage({
        perPage
      })
    );
  const perPage = useSelector(selectPerPage, shallowEqual);

  return {
    setPerPage: set,
    perPage
  };
}

export function useCurrentDir() {
  const dispatch = useDispatch();
  const currentDirId = useSelector(selectCurrentDir, shallowEqual);
  const setCurrent = (id: string | number) => dispatch(setCurrentDir({ id }));
  const reset = () => dispatch(resetCurrentDir());

  return {
    currentDirId,
    setCurrent,
    reset
  };
}

export function useResultsViewMode() {
  const persistKey = '@app.view_mode';
  const defaultMode =
    ((localStorage.getItem(persistKey) as unknown) as ViewMode) ||
    (ViewMode.Table as ViewMode);
  const [viewMode, setView] = useState<ViewMode>(defaultMode);
  const changeViewMode = (mode: ViewMode) => {
    localStorage.setItem(persistKey, String(mode));
    setView(mode);
  };

  return {
    viewMode,
    changeViewMode
  };
}

export function useUIError() {
  return useSelector(selectUIError, shallowEqual);
}
