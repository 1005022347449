import React from 'react';
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { List } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { NavigationListItem } from './components';
import { HasRole } from '../../modules/auth/components/HasRole';
import { Theme } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  list: {
    padding: 0
  }
}));

type NavigationListProps = {
  depth?: number;
  pages: any[];
  router?: any;
};

const NavigationList: React.FC<NavigationListProps> = props => {
  const { pages, ...rest } = props;
  const classes = useStyles();

  return (
    <List className={classes.list}>
      {pages.map(page => (
        <PageListItem key={page.href} page={page} {...rest} />
      ))}
    </List>
  );
};

type PageListItemProps = {
  page: any;
  depth?: number;
};

const RolesWrapper: React.FC<any> = ({ roles, children }) => (
  <HasRole roles={roles}>{children}</HasRole>
);

const NoneWrapper: React.FC<any> = ({ children }) => children;

export const PageListItem = (props: PageListItemProps): any => {
  const { page, depth = 0 } = props;
  const router = useRouter();
  const items = [];
  const Wrapper = page.authRoles ? RolesWrapper : NoneWrapper;

  if (page.children) {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false
    });

    items.push(
      <Wrapper roles={page.authRoles} key={page.title}>
        <NavigationListItem
          depth={depth}
          icon={page.icon}
          key={page.title}
          label={page.label}
          open={Boolean(open)}
          title={page.title}
          external={page.external}>
          <NavigationList
            depth={depth + 1}
            pages={page.children}
            router={router}
          />
        </NavigationListItem>
      </Wrapper>
    );
  } else {
    items.push(
      <Wrapper roles={page.authRoles} key={page.title}>
        <NavigationListItem
          depth={depth}
          href={page.href}
          icon={page.icon}
          key={page.title}
          label={page.label}
          title={page.title}
          external={page.external}
        />
      </Wrapper>
    );
  }

  return items;
};

type NavigationProps = {
  className?: string;
  component: any;
  pages: any[];
  title?: string;
};

const Navigation: React.FC<NavigationProps> = props => {
  const { title, pages, className, component: Component, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      <NavigationList depth={0} pages={pages} router={router} />
    </Component>
  );
};

Navigation.defaultProps = {
  component: 'nav'
};

export default Navigation;
