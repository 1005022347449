import http from '../../../utils/http';
import { CreateDirPayload, Dir, MoveDirPayload } from '../index';

interface FetchParams {
  preload?: boolean;
}

export function fetchDirs(params: FetchParams) {
  return http.get('api/directory/', { params });
}

export function createDir({ title, parent, directoryType }: CreateDirPayload) {
  return http.post('api/directory/', {
    title,
    parent,
    directoryType
  });
}

export function updateDir(data: Dir) {
  return http.put(`api/directory/${data.id}`, data);
}

export function patchUpdateDir(data: Dir) {
  return http.patch(`api/directory/${data.id}`, data);
}

export function moveDir(data: MoveDirPayload) {
  return http.put(`api/directory/move/${data.id}`, data);
}

export function readDir({ id }: Pick<Dir, 'id'>) {
  return http.get(`api/directory/${id}`);
}

export function deleteDir({ id }: Pick<Dir, 'id'>) {
  return http.delete(`api/directory/${id}`);
}
