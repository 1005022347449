import React from 'react';
import { Theme, Toolbar, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  toolbar: {
    padding: 0,
    minHeight: theme.spacing(6),
    backgroundColor: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel: React.FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" align="left" {...other}>
      <Box>{children}</Box>
    </Typography>
  );
};

interface Props {
  tabs: React.ReactElement;
  value?: number;
  swipeableClass?: string;
  toolbarClass?: string;
}

const SwipeableTabs: React.FC<Props> = ({
  value: defaultValue = 0,
  tabs,
  swipeableClass = '',
  toolbarClass = '',
  children
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(defaultValue);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Toolbar className={clsx(classes.toolbar, toolbarClass)}>
        {React.cloneElement(tabs, {
          value,
          onChange: handleChange
        })}
      </Toolbar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        ignoreNativeScroll={false}
        className={swipeableClass}>
        {React.Children.map(children, (child, index) => (
          <TabPanel value={value} index={index} dir={theme.direction}>
            {child}
          </TabPanel>
        ))}
      </SwipeableViews>
    </>
  );
};

export default SwipeableTabs;
