import React from 'react';
import { LinearProgress, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1150
  }
}));

export default function FixedLoader() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}
