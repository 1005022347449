import en from './en';
import pl from './pl';
import nl from './nl';
import fr from './fr';

export default {
  en,
  pl,
  nl,
  fr
};
