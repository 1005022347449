import {
  takeLatest,
  takeEvery,
  put,
  select,
  call,
  all
} from 'redux-saga/effects';
import { createApiHandler } from '../@common/sagas/create-api-handler';
import {
  fetchDirs,
  createDir,
  updateDir,
  patchUpdateDir,
  moveDir,
  readDir,
  deleteDir
} from './service/api';
import { dirsActions } from './actions';
import { transform } from '../../utils/transform-entities';
import { Dir } from './index';
import { isDirFetching } from './selectors';
import { handleFileUpload } from '../files/saga/file-upload';
import { QueuedFile } from '../files';
import { downloadWithAuth } from '../@common/sagas/file-downloader';

const handleDirsFetch = createApiHandler({
  routine: dirsActions.fetch,
  provider: fetchDirs,
  responseMiddleware: (response, payload) => ({
    items: transform(response.data),
    order: response.data.map((dirs: Dir) => dirs.id),
    isRoot: !payload.parentId
  })
});

const handleDirsPreload = createApiHandler({
  routine: dirsActions.preload,
  provider: () =>
    fetchDirs({
      preload: true
    }),
  responseMiddleware: response => ({
    items: transform(response.data)
  })
});

const singleDirCreateHandler = createApiHandler({
  routine: dirsActions.create,
  provider: createDir,
  maxRetries: 3
});

function* handleDirCreate(action: any) {
  yield call(handleCreateDirectoryTree, action.payload);
}

function* handleCreateDirectoryTree({
  title,
  parent,
  directoryType,
  files = [],
  children = []
}: any) {
  yield call(
    singleDirCreateHandler,
    dirsActions.create.trigger({
      title,
      parent,
      directoryType,
      files,
      children
    })
  );
}

const handleDirUpdate = createApiHandler({
  routine: dirsActions.update,
  provider: updateDir
});

const handleDirPatchUpdate = createApiHandler({
  routine: dirsActions.patchUpdate,
  provider: patchUpdateDir
});

const handleDirMove = createApiHandler({
  routine: dirsActions.move,
  provider: moveDir
});

const dirReadHandler = createApiHandler({
  routine: dirsActions.read,
  provider: readDir
});

function* handleDirRead(action: any) {
  const fetching = yield select(state =>
    isDirFetching(state, action.payload.id)
  );

  if (fetching) {
    return;
  }

  yield dirReadHandler(action);
}

const handleDirDelete = createApiHandler({
  routine: dirsActions.delete,
  provider: deleteDir
});

function* handleSuccessDirCreate(action: any) {
  const { parent } = action.payload;

  if (parent && parent.id) {
    yield put(dirsActions.read.trigger({ id: parent.id }));
  } else {
    yield put(dirsActions.fetch.trigger());
  }

  const dir = action.payload;
  const { files = [], children = [] } = action.meta;

  let chunkNum = 0;
  const chunkSize = 5;

  while (chunkNum * chunkSize < files.length) {
    const filesChunk = files.slice(
      chunkNum * chunkSize,
      (chunkNum + 1) * chunkSize
    );
    yield all(
      filesChunk.map((file: File & QueuedFile) =>
        call(handleFileUpload, file, dir.id)
      )
    );
    chunkNum++;
  }

  for (let child of children) {
    yield call(handleCreateDirectoryTree, {
      ...child,
      parent: dir.id
    });
  }
}

function* handleDirMoveSuccess(action: any) {
  const { parent } = action.meta;
  const parentId = parent > 0 ? parent : null;

  yield put(
    dirsActions.fetch.trigger({
      parentId
    })
  );
}

function* handleDirDownload(action: any) {
  const { id } = action.payload;
  const url = `/api/download/directory/${id}`;

  try {
    yield call(downloadWithAuth, url);
  } catch (e) {
    yield put(dirsActions.download.failure(action.payload, action.meta));
  } finally {
    yield put(dirsActions.download.fulfill());
  }
}

export default function*() {
  yield takeEvery(dirsActions.fetch.TRIGGER, handleDirsFetch);
  yield takeLatest(dirsActions.preload.TRIGGER, handleDirsPreload);
  yield takeEvery(dirsActions.create.TRIGGER, handleDirCreate);
  yield takeLatest(dirsActions.update.TRIGGER, handleDirUpdate);
  yield takeLatest(dirsActions.patchUpdate.TRIGGER, handleDirPatchUpdate);
  yield takeEvery(dirsActions.create.SUCCESS, handleSuccessDirCreate);
  yield takeEvery(dirsActions.read.TRIGGER, handleDirRead);
  yield takeEvery(dirsActions.delete.TRIGGER, handleDirDelete);

  yield takeLatest(dirsActions.move.TRIGGER, handleDirMove);
  yield takeLatest(dirsActions.move.SUCCESS, handleDirMoveSuccess);

  yield takeLatest(dirsActions.download.TRIGGER, handleDirDownload);
}
