import React from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  DialogContentText
} from '@material-ui/core';
import { FileIcon } from 'components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FolderIcon from '@material-ui/icons/Folder';
import CloseIcon from '@material-ui/icons/Close';
import { useBasketActions, useBasketItems } from '../hooks';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'utils/translation';

interface Props {
  //
}

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: '60vh'
  }
}));

export const BasketView: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { files, directories } = useBasketItems();
  const { removeFile, removeDirectory } = useBasketActions();
  const hasResources = files.length + directories.length > 0;

  if (hasResources) {
    return (
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <List className={classes.list}>
          {directories.map((dir, index) => (
            <React.Fragment key={`dir_${dir.id}`}>
              <ListItem>
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText>{dir.title}</ListItemText>
                <IconButton
                  size="small"
                  onClick={() => removeDirectory({ dir })}>
                  <CloseIcon />
                </IconButton>
              </ListItem>
              {(index < directories.length - 1 || files.length > 0) && (
                <Divider />
              )}
            </React.Fragment>
          ))}
          {files.map((file, index) => (
            <React.Fragment key={`file_${file.id}`}>
              <ListItem>
                <ListItemIcon>
                  <FileIcon type={file.type} />
                </ListItemIcon>
                <ListItemText>{file.filename}</ListItemText>
                <IconButton size="small" onClick={() => removeFile({ file })}>
                  <CloseIcon />
                </IconButton>
              </ListItem>
              {index < files.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </PerfectScrollbar>
    );
  }

  return <DialogContentText>{t('Basket is empty')}</DialogContentText>;
};
