import navigation from './navigation.json';
import global from './global.json';
import users from './users.json';
import myDisk from './myDisk.json';

export default {
  global,
  users,
  navigation,
  myDisk
};
