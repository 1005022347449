import React from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';

interface Props {
  onClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(0.5),
    padding: theme.spacing(2),
    zIndex: 50
  }
}));

const ModalClose: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClose} className={classes.button}>
      <CloseIcon />
    </IconButton>
  );
};

export default ModalClose;
