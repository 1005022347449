import { RootState } from '../../store';
import { File } from './index';

function root(state: RootState) {
  return state.files;
}

export function selectFile(state: RootState, id: number) {
  return root(state).items[id];
}

export function selectFileThumbnail(state: RootState, id: number) {
  const file = selectFile(state, id);

  if (file) {
    return file.thumbnailUrl;
  }

  return null;
}

export function selectRootFiles(state: RootState) {
  const order = root(state).order as number[];

  return order
    .map(id => selectFile(state, id))
    .filter((item: File | undefined) => item);
}

export function selectDirFiles(state: RootState, dirId: number) {
  const dir = state.dirs.items[dirId];

  if (!dir) {
    return [];
  }

  return (dir.filesIds || [])
    .map((id: number) => selectFile(state, id))
    .filter((item: File | undefined) => item);
}

export function selectFilesFetching(state: RootState) {
  return root(state).isFetching;
}

export function selectFormState(state: RootState) {
  return {
    isSubmitting: root(state).isSubmitting,
    didCreate: root(state).didCreate,
    didUpdate: root(state).didUpdate,
    errors: root(state).errors
  };
}

export function selectUploadingItems(state: RootState) {
  return Object.values(state.upload.items)
    .sort((a, b) => {
      return a.createdAt - b.createdAt;
    })
    .map(item => item.id);
}

export function selectUploadingItemById(state: RootState, id: string) {
  return state.upload.items[id];
}
