import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Grid, Typography, Button, Hidden } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import InputIcon from '@material-ui/icons/Input';
import { AdminRole } from 'modules/auth';
import { useTranslation } from 'utils/translation';
import { Theme } from 'theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2)
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column'
      }
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

interface Props {
  className?: string;
  onDelete?(): void;
  onMove?(): void;
  extraActions?(): ReactElement | null;
  selected: any[];
}
const TableEditBar: React.FC<Props> = props => {
  const {
    selected,
    className,
    onMove,
    onDelete,
    extraActions,
    ...rest
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const open = selected.length > 0;

  return (
    <Drawer
      anchor="bottom"
      open={open}
      PaperProps={{ elevation: 1, style: { zIndex: 900 } }}
      variant="persistent">
      <div {...rest} className={clsx(classes.root, className)}>
        <Grid alignItems="center" container spacing={2}>
          <Hidden smDown>
            <Grid item md={3}>
              <Typography color="textSecondary" variant="subtitle1">
                {t('{{ count }} selected', { count: selected.length })}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item md={6} xs={12}>
            <div className={classes.actions}>
              {extraActions && extraActions()}
              {onMove && (
                <Button onClick={onMove}>
                  <InputIcon className={classes.buttonIcon} />
                  {t('Move')}
                </Button>
              )}
              {onDelete && (
                <AdminRole>
                  <Button onClick={onDelete}>
                    <DeleteIcon className={classes.buttonIcon} />
                    {t('Delete')}
                  </Button>
                </AdminRole>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

export default TableEditBar;
