import React from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FormikBag, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'utils/translation';
import { User } from '../index';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0
  }
}));

export interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
}

type FormBag = FormikBag<OtherProps, FormValues>;

interface OtherProps {
  onCancel: () => void;
  onSubmit: (values: FormValues, bag: FormBag) => void;
  initialValues?: Partial<User>;
}

const BaseProfileForm = (props: OtherProps & FormikProps<FormValues>) => {
  const classes = useStyles();
  const { t } = useTranslation(['users', 'validation']);

  const {
    values,
    touched,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,

    onCancel
  } = props;

  const getError = (field: keyof FormValues) => {
    if (touched[field] && errors[field]) {
      return errors[field];
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={12} lg={6}>
          <FormControl error={Boolean(getError('firstName'))} fullWidth>
            <InputLabel>{t('First name')}</InputLabel>
            <Input
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
            />
            {getError('firstName') && (
              <FormHelperText>
                {t(`validation:${getError('firstName')}`)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl error={Boolean(getError('lastName'))} fullWidth>
            <InputLabel>{t('Last name')}</InputLabel>
            <Input
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              autoComplete="off"
            />
            {getError('lastName') && (
              <FormHelperText>
                {t(`validation:${getError('lastName')}`)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl error={Boolean(getError('email'))} fullWidth>
            <InputLabel>{t('Email address')}</InputLabel>
            <Input
              name="email"
              value={values.email}
              autoComplete="off"
              onChange={handleChange}
            />
            {getError('email') && (
              <FormHelperText>
                {t(`validation:${getError('email')}`)}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <CardActions className={classes.actions}>
        <Button onClick={onCancel} variant="contained">
          {t('Cancel')}
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress size={24} /> : t('Save')}
        </Button>
      </CardActions>
    </form>
  );
};

const buildValidationSchema = (props: OtherProps) => {
  return Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string()
      .email('Invalid email')
      .required('Required')
  });
};

export const ProfileForm = withFormik<OtherProps, FormValues>({
  mapPropsToValues: ({ initialValues = {} }) => {
    return {
      firstName: initialValues.firstName || '',
      lastName: initialValues.lastName || '',
      email: initialValues.email || ''
    };
  },
  validationSchema: buildValidationSchema,
  handleSubmit: (values, bag) => {
    const { onSubmit } = bag.props;
    onSubmit(values, bag);
  }
})(BaseProfileForm);

export type Form = {
  Values: FormValues;
  Bag: FormBag;
};
