import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Dir } from '../../modules/dirs';
import DirectoryPicker from './DirectoryPicker';

type SuccessResult = {
  canceled: false;
  id: Dir['id'];
};

type CancelResult = {
  canceled: true;
};

type OpenResult = SuccessResult | CancelResult;

type ContextType = {
  selectDirectory(): Promise<OpenResult>;
};

const DirectoryPickerContext = React.createContext<ContextType | null>(null);

export const useDirectoryPicker = () => {
  const context = useContext(DirectoryPickerContext);

  if (null === context) {
    throw new Error(
      'DirectoryPickerContext is null.' +
        ' Did you forget to wrap your component into DirectoryPickerProvider?'
    );
  }

  return context;
};

const DirectoryPickerProvider: React.FC = ({ children }) => {
  const [stack, setStack] = useState<((result: OpenResult) => void)[]>([]);

  useEffect(() => {
    return () => {
      stack.forEach(item => item({ canceled: true }));
    };
  }, [stack]);

  const selectDirectory = useCallback(() => {
    return new Promise<OpenResult>(resolve => {
      setStack(prev => [...prev, resolve]);
    });
  }, [setStack]);

  const handleSelect = useCallback(
    (id: Dir['id']) => {
      const current = stack.slice(-1)[0];
      const nextStack = stack.slice(0, -1);

      current({ canceled: false, id });

      setStack(nextStack);
    },
    [stack, setStack]
  );

  const handleCancel = useCallback(() => {
    const current = stack.slice(-1)[0];
    const nextStack = stack.slice(0, -1);

    current({ canceled: true });
    setStack(nextStack);
  }, [stack, setStack]);

  const contextValue = useMemo(() => ({ selectDirectory }), [selectDirectory]);

  return (
    <DirectoryPickerContext.Provider value={contextValue}>
      {children}
      {stack.length > 0 && (
        <DirectoryPicker onSelect={handleSelect} onClose={handleCancel} />
      )}
    </DirectoryPickerContext.Provider>
  );
};

export default DirectoryPickerProvider;
