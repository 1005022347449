import { RootState } from '../../store';
import { selectFile } from '../files/selectors';
import { selectDir } from '../dirs/selectors';

export function selectBasketItems(state: RootState) {
  const files = state.basket.files
    .map(id => selectFile(state, id))
    .filter(item => item);

  const directories = state.basket.directories
    .map(id => selectDir(state, id))
    .filter(item => item);

  return {
    files,
    directories
  };
}

export function isFileInBasket(state: RootState, id: number) {
  return state.basket.files.includes(id);
}

export function isDirInBasket(state: RootState, id: number) {
  return state.basket.directories.includes(id);
}
