import config from 'config/api';
import { select, call, race, delay } from 'redux-saga/effects';
import fileDownload from '../../../utils/file-download';

export function* downloadWithAuth(url: string) {
  const token = yield select(state => state.auth.accessToken);
  const rootUrl = config.url;
  const fullUrl = `${rootUrl}${url}?access_token=${token}`;

  const { timeout } = yield race({
    result: call(fileDownload, { url: fullUrl }),
    timeout: delay(10000)
  });

  if (timeout) {
    throw new Error('Timeout exceed during downloading');
  }
}
