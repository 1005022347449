import React from 'react';
import { SnackBar } from './types';
import SnackbarContext from './snackbar-context';
import Snackbar from './Snackbar';

const SnackBarsProvider: React.FC = ({ children }) => {
  const [items, setItems] = React.useState<Array<SnackBar>>([]);

  const add = React.useCallback(
    (item: SnackBar) => {
      setItems(prev => [...prev, item]);
    },
    [setItems]
  );

  const remove = React.useCallback(
    (item: SnackBar) => {
      setItems(prev => prev.filter(i => i !== item));
    },
    [setItems]
  );

  return (
    <SnackbarContext.Provider value={{ add, remove }}>
      {children}
      {items.map((item, index) => (
        <Snackbar key={index} {...item} />
      ))}
    </SnackbarContext.Provider>
  );
};

export default SnackBarsProvider;
