import global from './global.json';
import messages from './messages.json';
import navigation from './navigation.json';
import validation from './validation.json';
import myDisk from './myDisk.json';
import users from './users.json';

export default {
  global,
  messages,
  navigation,
  validation,
  myDisk,
  users
};
