import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { PDFViewer, PhotoViewer } from 'components';
import ReactPlayer from 'react-player';
import { makeStyles } from '@material-ui/core/styles';
import { useShouldModalBeFullscreen } from 'utils/media-query';
import { File } from '../../index';
import { images, pdfs, otherDocuments, videos, audios } from '../../utils';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 !important',
    height: '95vh',
    alignItems: 'center'
  },
  videoContent: {
    display: 'flex',
    minHeight: '550px',
    flexDirection: 'row',
    padding: '0 !important',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    overflow: 'hidden',
    borderRadius: 0
  }
}));

interface PreviewProps {
  onClose(): void;
  src: string;
  loader?: any;
}

export const DocumentPreviewModal: React.FC<PreviewProps> = ({
  onClose,
  src,
  loader = null
}) => {
  const classes = useStyles();
  const fullScreen = useShouldModalBeFullscreen();

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="lg"
      PaperProps={{ className: classes.paper }}>
      <DialogContent className={classes.content}>
        <PDFViewer url={src} onClose={onClose} loader={loader} />
      </DialogContent>
    </Dialog>
  );
};

export const VideoPreviewModal: React.FC<PreviewProps> = ({ onClose, src }) => {
  const classes = useStyles();
  const fullScreen = useShouldModalBeFullscreen();

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="md"
      PaperProps={{ className: classes.paper }}>
      <DialogContent className={classes.videoContent}>
        <ReactPlayer url={src} playing controls width="900px" height="540px" />
      </DialogContent>
    </Dialog>
  );
};

export const OfficeDocumentPreviewModal: React.FC<PreviewProps> = ({
  onClose,
  src
}) => {
  const classes = useStyles();
  const fullScreen = useShouldModalBeFullscreen();

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="lg"
      PaperProps={{ className: classes.paper }}>
      <DialogContent
        className={classes.content}
        style={{ overflow: 'hidden', alignItems: 'flex-start' }}>
        <iframe
          title="MS Document preview"
          style={{
            width: '100%',
            height: 'calc(100% + 23px)'
          }}
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            src
          )}`}
          frameBorder="0"
        />
      </DialogContent>
    </Dialog>
  );
};

interface Props {
  file: File;
  onClose(): void;
}

const FilePreviewModal: React.FC<Props> = ({ file, onClose }) => {
  if (!file.previewUrl) {
    return null;
  }

  if (images.includes(file.type)) {
    return <PhotoViewer onClose={onClose} src={file.previewUrl} />;
  }

  if (pdfs.includes(file.type)) {
    return <DocumentPreviewModal onClose={onClose} src={file.previewUrl} />;
  }

  if (videos.includes(file.type) || audios.includes(file.type)) {
    if (!file.originalUrl) {
      return null;
    }

    return <VideoPreviewModal onClose={onClose} src={file.originalUrl} />;
  }

  if (otherDocuments.includes(file.type)) {
    if (!file.originalUrl) {
      return null;
    }

    return (
      <OfficeDocumentPreviewModal onClose={onClose} src={file.originalUrl} />
    );
  }

  return null;
};

export default FilePreviewModal;
