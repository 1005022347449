import validate from 'validate.js';

const regex = (value, options) => {
  const regExp = new RegExp(options.pattern);

  if (!regExp.test(value)) {
    return options.message;
  }
};

const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

const isEmail = (value, options) => {
  const pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return regex(value, {
    ...options,
    pattern
  });
};

validate.validators = {
  ...validate.validators,
  regex,
  checked,
  isEmail
};
