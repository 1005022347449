import { call, take, put } from 'redux-saga/effects';
import { createUploadFileChannel } from './upload-channel';
import { filesActions } from '../actions';
import { QueuedFile } from '../index';

export function* handleFileUpload(file: File & QueuedFile, dirId?: number) {
  const id = file.id;
  const channel = yield call(createUploadFileChannel, file, dirId);

  yield put(filesActions.create.request({ id, file, dirId }));

  while (true) {
    const { progress = 0, error, success, response } = yield take(channel);

    if (error) {
      yield put(filesActions.create.failure({ id, file, error }));
      yield put(filesActions.create.fulfill({ id }));
      return;
    }

    if (success) {
      yield put(filesActions.create.success(response));
      yield put(filesActions.create.fulfill({ id }));
      return;
    }

    yield put(
      filesActions.uploadProgress({
        id,
        file,
        progress
      })
    );
  }
}
