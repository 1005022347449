import React, { ChangeEvent, useEffect, useState } from 'react';
import { validate } from 'validate.js';
import { ChipInput } from '../index';
import { Box } from '@material-ui/core';

const isValidEmail = (email: string) => {
  const errors = validate(
    { email },
    {
      email: {
        presence: true,
        isEmail: {
          message: 'Must be a valid e-mail'
        }
      }
    }
  );

  return Object.keys(errors || {}).length === 0;
};

type Email = string;

interface PickerProps {
  placeholder?: string;
  initialEmails?: Email[];
  onChange?(emails: Email[]): void;
}

const EmailsChipsPicker: React.FC<PickerProps> = ({
  initialEmails = [],
  onChange,
  placeholder
}) => {
  const [inputValue, setInputVal] = useState('');
  const [error, setError] = useState(false);
  const [emails, setEmails] = useState<string[]>(initialEmails);

  useEffect(() => {
    if (onChange) {
      onChange(emails);
    }
  }, [emails, onChange]);

  const handleAddEmail = (emailAddress: string) => {
    const email = (emailAddress || '').trim();

    if (isValidEmail(email)) {
      setEmails(prev => [...prev, email]);
      setInputVal('');
    } else {
      setError(true);
    }
  };

  const handleDeleteEmail = (email: string) => {
    setEmails(emails.filter(e => e !== email));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputVal(event.target.value);
    setError(false);
  };

  return (
    <Box>
      <ChipInput
        error={error}
        value={emails}
        onAdd={chip => handleAddEmail(chip)}
        onDelete={chip => handleDeleteEmail(chip)}
        fullWidth
        fullWidthInput
        blurBehavior="add"
        newChipKeyCodes={[9, 13, 188, 32]} // Tab, Enter, Comma, Space
        inputValue={inputValue}
        placeholder={placeholder}
        variant="outlined"
        onUpdateInput={handleInputChange}
      />
    </Box>
  );
};

export default EmailsChipsPicker;
