import React from 'react';
import { useCurrentUser } from '../hooks';
import { Avatar, IconButton, Theme } from '@material-ui/core';
import getInitials from 'utils/getInitials';
import { useTranslation } from 'utils/translation';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import InputIcon from '@material-ui/icons/Input';
import PersonIcon from '@material-ui/icons/Person';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import { logout } from '../actions';
import { EditProfileModal } from '../../users/containers/EditProfileModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: {
    paddingRight: 0
  },
  avatar: {
    height: 40,
    width: 40,
    fontSize: 16,
    letterSpacing: '0.1em',
    backgroundColor: 'transparent',
    border: '2px solid'
  },
  menuItem: {
    minWidth: '200px'
  },
  listIcon: {
    marginRight: theme.spacing(0.5)
  }
}));

const CurrentUserActions = () => {
  const classes = useStyles();
  const { t } = useTranslation('users');
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const [isProfileEdit, setIsProfileEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const fullName = [user.firstName, user.lastName].filter(i => i).join(' ');

  const handleLogout = () => {
    dispatch(logout.trigger());
  };

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleShowProfileEdit() {
    setIsProfileEdit(true);
    setAnchorEl(null);
  }

  function handleCloseProfileEdit() {
    setIsProfileEdit(false);
  }

  return (
    <div className={classes.root}>
      <IconButton className={classes.button} onClick={handleClick}>
        <Avatar className={classes.avatar}>
          {getInitials(fullName || user.email)}
        </Avatar>
      </IconButton>
      <Menu
        id="app-current-user-actions"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem className={classes.menuItem} onClick={handleShowProfileEdit}>
          <PersonIcon className={classes.listIcon} />
          {t('Edit Profile')}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleLogout}>
          <InputIcon className={classes.listIcon} />
          {t('Logout')}
        </MenuItem>
      </Menu>
      {isProfileEdit && (
        <EditProfileModal open={true} onClose={handleCloseProfileEdit} />
      )}
    </div>
  );
};

export { CurrentUserActions };
