import React from 'react';
import { FieldProps } from './types';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel
} from '@material-ui/core';

const RadioGroupField: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  onChange,
  settings = {}
}) => {
  const options = Array.from(settings.options || []) as any[];
  if (!value) {
    value = '';
  }

  return (
    <FormControl fullWidth error={!!errorMessage} component="fieldset">
      {errorMessage && <FormLabel>{errorMessage}</FormLabel>}
      <RadioGroup name={name} value={value} onChange={onChange}>
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export { RadioGroupField };
