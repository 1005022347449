import React from 'react';
import { Dialog, DialogContent, Theme, Typography } from '@material-ui/core';
import { SwipeableTabs } from 'components';
import { useTranslation } from 'utils/translation';
import { UpdateProfile } from './UpdateProfile';
import { ProfilePasswordChange } from './ProfilePasswordChange';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/styles';
import { ModalClose } from '../../../components';
import { useShouldModalBeFullscreen } from '../../../utils/media-query';

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    padding: theme.spacing(0, 1)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export const EditProfileModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation('users');
  const fullScreen = useShouldModalBeFullscreen();
  const classes = useStyles();

  return (
    <Dialog
      aria-labelledby="profile-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm">
      <DialogContent>
        <ModalClose onClose={onClose} />
        <Typography align="center" variant="h3" gutterBottom>
          {t('Edit Profile')}
        </Typography>
        <SwipeableTabs
          tabs={
            <Tabs value={0} variant="standard">
              <Tab label={t('Account information')} />
              <Tab label={t('Change password')} />
            </Tabs>
          }>
          <div className={classes.tab}>
            <UpdateProfile onCancel={onClose} />
          </div>
          <div className={classes.tab}>
            <ProfilePasswordChange onCancel={onClose} />
          </div>
        </SwipeableTabs>
      </DialogContent>
    </Dialog>
  );
};
