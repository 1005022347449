import React from 'react';
import { Box, Fab, Divider, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ReactComponent as CreateDocumentIcon } from 'assets/icons/add-document.svg';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { useCurrentDir, useUiModal } from '../../../../../modules/ui/hooks';
import {
  CREATE_DIRECTORY_MODAL_ID,
  CREATE_OBJECT_TYPE_MODAL_ID
} from '../../../../../modules/ui';
import { AdminRole } from '../../../../../modules/auth';
import { applyFilters, Filters } from '../../../../../core';
import useRouter from '../../../../../utils/useRouter';
import { useSingleDir } from '../../../../../modules/dirs/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fab: {
    margin: theme.spacing(1.5)
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  menu: {},
  menuItem: {
    minWidth: theme.spacing(27),
    minHeight: theme.spacing(4),
    padding: theme.spacing(1, 4)
  }
}));

const NavBarActions: React.FC = () => {
  const classes = useStyles();
  const router = useRouter();
  const { t } = useTranslation('navigation');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { currentDirId } = useCurrentDir();
  const { dir } = useSingleDir(currentDirId as number);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const { show } = useUiModal();

  const handleCreateDirClick = () => {
    show(CREATE_DIRECTORY_MODAL_ID);
  };

  const handleCreateObjectTypeClick = () => {
    show(CREATE_OBJECT_TYPE_MODAL_ID);
  };

  const handleNewUploadClick = () => {
    const input = document.getElementById('file-upload-input');
    if (input) {
      input.click();
    }
  };

  const extraActions = [
    {
      id: 'New Object Type',
      icon: CreateDocumentIcon,
      action: handleCreateObjectTypeClick
    }
  ];

  const defaultActions = [
    {
      id: 'New folder',
      icon: CreateNewFolderIcon,
      action: handleCreateDirClick
    },
    {
      id: 'New upload',
      icon: CloudUploadIcon,
      action: handleNewUploadClick
    }
  ];

  const actions = applyFilters(Filters.navbar.actions, extraActions);
  const standardActions = applyFilters(
    Filters.navbar.defaultActions,
    defaultActions,
    {
      router,
      dir
    }
  );

  const shouldShow = applyFilters(Filters.navbar.actionsVisibility, true, {
    router
  });

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <div className={classes.actions}>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          size="small"
          onClick={handleClick}>
          <AddIcon />
        </Fab>
        <Menu
          PaperProps={{
            className: classes.menu
          }}
          id="navbar-actions"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {actions.map(({ id, icon: Icon, action }) => (
            <MenuItem
              className={classes.menuItem}
              key={id}
              onClick={() => {
                action && action();
                handleClose();
              }}>
              <Icon className={classes.icon} />
              {t(id)}
            </MenuItem>
          ))}
          {actions.length > 0 && (
            <Box my={1} mx={4}>
              <Divider />
            </Box>
          )}
          {standardActions.map(({ id, icon: Icon, action }) => (
            <MenuItem
              className={classes.menuItem}
              key={id}
              onClick={() => {
                action && action();
                handleClose();
              }}>
              <Icon className={classes.icon} />
              {t(id)}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <Divider />
    </>
  );
};

export default React.memo(() => (
  <AdminRole>
    <NavBarActions />
  </AdminRole>
));
