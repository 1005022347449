import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { usersActions, usersPaginateActions } from './actions';
import {
  selectUsers,
  selectUser,
  isFetching,
  isUserFetching
} from './selectors';
import { SelectUsersParams, User } from './index';
import { RootState } from '../../store';
import { useCallback } from 'react';
import { useApiCall } from '../@common/hooks';
import {
  changeProfilePassword,
  changeUserPassword,
  createUserApiCredentials,
  fetchUserApiCredentials,
  regeneratePasswordLink,
  removeUserApiCredentials,
  updateProfile
} from './service/api';

export const useUserActions = () => {
  const dispatch = useDispatch();
  const changeStatus = (user: User, status: boolean) =>
    dispatch(
      usersActions.statusChange.trigger({
        user,
        status
      })
    );

  return {
    changeStatus
  };
};

export function useSingleUser(id: number) {
  const dispatch = useDispatch();
  const user = useSelector(
    (state: RootState) => selectUser(state, id),
    shallowEqual
  );
  const isFetching = useSelector(
    (state: RootState) => isUserFetching(state, id),
    shallowEqual
  );
  const readUser = useCallback(
    () => dispatch(usersActions.read.trigger({ id })),
    [dispatch, id]
  );

  return {
    readUser,
    user,
    isFetching
  };
}

export function useUsersList(params: SelectUsersParams = {}) {
  const dispatch = useDispatch();

  const users = useSelector(
    (state: RootState) => selectUsers(state, params),
    shallowEqual
  ) as User[];
  const isLoading = useSelector(isFetching, shallowEqual);
  const fetchUsers = () => dispatch(usersActions.fetch.trigger());
  const deleteUser = (id: number) =>
    dispatch(usersActions.delete.trigger({ id }));
  const changePage = (page: number) =>
    dispatch(usersPaginateActions.changePage({ page }));
  const changePerPage = (page: number) =>
    dispatch(usersPaginateActions.changePerPage({ page }));

  return {
    users,
    isLoading,
    fetchUsers,
    deleteUser,
    changePage,
    changePerPage
  };
}

export function useUserPasswordChangeApiCall() {
  return useApiCall({
    provider: changeUserPassword
  });
}

export function useProfileUpdate() {
  return useApiCall({
    provider: updateProfile
  });
}

export function useProfilePasswordChange() {
  return useApiCall({
    provider: changeProfilePassword
  });
}

export function useUserApiCredentials() {
  return useApiCall({
    provider: fetchUserApiCredentials
  });
}

export function useCreateUserApiCredentials() {
  return useApiCall({
    provider: createUserApiCredentials
  });
}

export function useRemoveUserApiCredentials() {
  return useApiCall({
    provider: removeUserApiCredentials
  });
}

export function useRegeneratePasswordLink() {
  return useApiCall({
    provider: regeneratePasswordLink
  });
}
