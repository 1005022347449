import i18n from 'i18next';
import LanguageDetector, {
  DetectorOptions
} from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation, Trans } from 'react-i18next';
import transitions from '../translations';
import localeConfig from 'config/locale';
import moment from 'moment';

const detector = new LanguageDetector(null, {
  order: [
    'url-prefix',
    'querystring',
    'cookie',
    'localStorage',
    'navigator',
    'htmlTag'
  ]
});

detector.addDetector({
  name: 'url-prefix',
  lookup(options: DetectorOptions): string | undefined {
    if (!window) {
      return;
    }

    const lang = window.location.pathname.split('/');

    if (typeof lang[1] !== undefined) {
      const code = lang[1];
      if (localeConfig.locales.includes(code) && code) {
        return code;
      }
    }
  }
});

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(detector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      resources: transitions,
      defaultNS: 'global',
      fallbackLng: localeConfig.fallbackLocale,
      debug: false,
      interpolation: {
        escapeValue: false
      }
    },
    () => {
      moment.locale(i18n.language);
    }
  );

export { useTranslation, Trans };

export default i18n;
