import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';
import PickerDialog from './PickerDialog';
import clsx from 'clsx';

interface Props {
  className?: string;
  value: string;
  onChange(color: string): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative'
  },
  color: {
    color: theme.palette.text.secondary,
    border: '1px solid',
    margin: theme.spacing(1, 0),
    width: 60,
    height: 40,
    cursor: 'pointer'
  }
}));

const ColorPicker: React.FC<Props> = ({ className, value, onChange }) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const showPicker = () => {
    setActive(true);
  };

  const hidePicker = () => {
    setActive(false);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div
        className={classes.color}
        style={{ backgroundColor: value }}
        onClick={showPicker}
      />
      {active && (
        <PickerDialog value={value} onChange={onChange} onClick={hidePicker} />
      )}
    </div>
  );
};

export default ColorPicker;
