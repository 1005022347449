import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InboxIcon from '@material-ui/icons/Inbox';
import { BasketView } from './BasketView';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'utils/translation';
import { Theme } from '../../../theme';
import { BasketTabs } from './BasketTabs';
import { ModalClose } from '../../../components';
import { useShouldModalBeFullscreen } from '../../../utils/media-query';

const useStyles = makeStyles((theme: Theme) => ({
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    display: 'flex',
    fontSize: 24
  },
  icon: {
    display: 'inline-flex',
    alignSelf: 'center'
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export const BasketViewModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation('navigation');
  const fullScreen = useShouldModalBeFullscreen();

  return (
    <Dialog
      aria-labelledby="basket-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm">
      <ModalClose onClose={onClose} />
      <DialogTitle disableTypography>
        <Typography variant="h3" className={classes.title}>
          <InboxIcon className={classes.icon} />
          {t('Basket')}
        </Typography>
        <IconButton className={classes.close} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <BasketTabs />
      <DialogContent>
        <BasketView />
      </DialogContent>
    </Dialog>
  );
};
