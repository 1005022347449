import React from 'react';
import { ChromePicker } from 'react-color';

interface Props {
  value: string;
  onClick?(): void;
  onChange(color: string): void;
}

const PickerDialog: React.FC<Props> = ({ value, onClick, onChange }) => (
  <div style={{ position: 'absolute', zIndex: 2 }}>
    <div
      style={{
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }}
      onClick={onClick}
    />
    <ChromePicker
      disableAlpha
      color={value}
      onChange={({ hex }) => onChange(hex)}
    />
  </div>
);

export default PickerDialog;
