import React from 'react';
import 'date-fns';
import moment from 'moment';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { FormControl, InputLabel } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MaterialUiPickersDate
} from '@material-ui/pickers';
import { FieldProps } from './types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  error: {
    transform: 'translateY(-50%)'
  }
}));

const DateField: React.FC<FieldProps> = ({
  value,
  name,
  form,
  errorMessage,
  settings = {}
}) => {
  const classes = useStyles();
  const attributes = pick(settings, [
    'disablePast',
    'disableFeature',
    'minDateMessage',
    'maxDateMessage'
  ]);
  const format = get(settings, 'format', 'DD-MM-YYYY');
  let currentValue = null;
  try {
    if (value && !Number.isNaN(+value)) {
      const date = moment.utc(+value * 1000);
      currentValue = date.toDate();
    }
  } catch (e) {}

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date) {
      const utc = moment.utc(date.format('YYYY-MM-DD'), 'YYYY-MM-DD');
      form.setFieldValue(name, (+utc / 1000).toString(), false);
    } else {
      form.setFieldValue(name, null, false);
    }
  };

  return (
    <FormControl variant="outlined" error={!!errorMessage}>
      <KeyboardDatePicker
        {...attributes}
        variant="dialog"
        format={format}
        value={currentValue}
        onChange={handleDateChange}
        disabled={settings.readOnly}
      />
      {errorMessage && (
        <InputLabel className={classes.error}>{errorMessage}</InputLabel>
      )}
    </FormControl>
  );
};

export { DateField };
