import React, { Component } from 'react';
import SortableTree, {
  ExtendedNodeData,
  FullTree,
  NodeData,
  OnMovePreviousAndNextLocation,
  OnVisibilityToggleData,
  TreeIndex,
  TreeNode
} from 'react-sortable-tree';
import theme from './Theme';

interface TreeProps {
  treeData: any[];
  isVirtualized?: boolean;
  visibleLength: number;
  onChange: (treeData: any[]) => void;
  onBeforeChildrenRender: ({ node }: any) => any | null;
  onMoveNode?(data: NodeData & FullTree & OnMovePreviousAndNextLocation): void;
  onVisibilityToggle?(data: OnVisibilityToggleData): void;
  getNodeKey?(data: TreeNode & TreeIndex): string | number;
  generateNodeProps?(data: ExtendedNodeData): { [index: string]: any };
  canDrag?: ((data: ExtendedNodeData) => boolean) | boolean;
}

export default class Tree extends Component<TreeProps> {
  static defaultProps = {
    onBeforeChildrenRender: () => null,
    onChange: () => {},
    onMoveNode: () => {},
    onVisibilityToggle: () => {}
  };

  render() {
    const {
      isVirtualized = false,
      treeData,
      getNodeKey,
      onChange,
      canDrag,
      onMoveNode,
      onVisibilityToggle,
      generateNodeProps,
      visibleLength
    } = this.props;

    const itemHeight = 30;
    const winHeight = (window && window.innerHeight) * 0.45;
    const itemsHeight = visibleLength * itemHeight;
    let height = Math.min(winHeight, itemsHeight);

    return (
      <div
        className="custom-scrollbar"
        style={{ height: isVirtualized ? height : '100%' }}>
        <SortableTree
          canDrag={canDrag}
          isVirtualized={isVirtualized}
          treeData={treeData}
          onChange={onChange}
          onVisibilityToggle={onVisibilityToggle}
          onMoveNode={onMoveNode}
          getNodeKey={getNodeKey}
          generateNodeProps={generateNodeProps}
          // @ts-ignore
          theme={theme}
        />
      </div>
    );
  }
}
