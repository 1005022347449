import React from 'react';
import clsx from 'clsx';
import {
  isDir,
  isFile,
  isInheritedDir,
  isObjectTypeDir,
  isUniqueObjectDir,
  Resource
} from '../../../../modules/resources';
import { ReactComponent as InheritedDirIcon } from '../../../../assets/icons/inherited.svg';
import { ReactComponent as UniqueDirIcon } from '../../../../assets/icons/object-id.svg';
import { ReactComponent as ObjectTypeIcon } from '../../../../assets/icons/object.svg';
import FolderIcon from '@material-ui/icons/Folder';
import { FileIcon } from '../../../../components';

interface Props {
  item: Resource;
  className?: string;
}

const ResourceIcon: React.FC<Props> = ({ item, className }) => {
  if (isDir(item)) {
    if (isObjectTypeDir(item)) {
      return <ObjectTypeIcon className={clsx('MuiSvgIcon-root', className)} />;
    }

    if (isUniqueObjectDir(item)) {
      return <UniqueDirIcon className={clsx('MuiSvgIcon-root', className)} />;
    }

    if (isInheritedDir(item)) {
      return (
        <InheritedDirIcon className={clsx('MuiSvgIcon-root', className)} />
      );
    }

    return <FolderIcon className={clsx('MuiSvgIcon-root', className)} />;
  }

  if (isFile(item)) {
    return <FileIcon className={className} type={item.fileType} />;
  }

  return <FolderIcon className={className} />;
};

export default ResourceIcon;
