import React, { ChangeEvent, useState } from 'react';
import { ChipInput } from 'components';
import { validate } from 'validate.js';
import { useTranslation } from 'utils/translation';
import {
  Theme,
  Typography,
  Button,
  CircularProgress,
  Chip
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/styles';
import { useBasketShare } from '../hooks/use-basket-share';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(3, 0),
    position: 'relative'
  },
  error: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  actions: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    justifyContent: 'flex-end'
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: theme.spacing(2, 0)
  },
  resultItem: {
    margin: theme.spacing(2, 1)
  },
  sendButton: {
    '& svg': {
      marginRight: theme.spacing(1)
    }
  }
}));

const ShareBasketView = () => {
  const { t } = useTranslation(['global', 'validation', 'myDisk']);
  const classes = useStyles();
  const [inputValue, setInputVal] = useState('');
  const [error, setError] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const {
    isLoading,
    response,
    request: shareRequest,
    reset
  } = useBasketShare();

  const isValidEmail = (email: string) => {
    const errors = validate(
      { email },
      {
        email: {
          presence: true,
          isEmail: {
            message: 'Must be a valid e-mail'
          }
        }
      }
    );

    return Object.keys(errors || {}).length === 0;
  };

  const handleReset = () => {
    reset();
    setInputVal('');
    setError(false);
    setEmails([]);
  };

  const handleAddEmail = (emailAddress: string) => {
    const email = (emailAddress || '').trim();

    if (isValidEmail(email)) {
      setEmails(prev => [...prev, email]);
      setInputVal('');
    } else {
      setError(true);
    }
  };

  const handleDeleteEmail = (email: string) => {
    setEmails(emails.filter(e => e !== email));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputVal(event.target.value);
    setError(false);
  };

  const handleButtonClick = () => {
    shareRequest(emails);
  };

  if (response) {
    const emails = response.emails || [];
    const count = Array.from(emails).length;

    return (
      <div>
        <Typography>
          {t('Sent share notification to {{ count }} receiver(s)', { count })}
        </Typography>
        <div>
          {emails.map((email: string) => (
            <Chip className={classes.resultItem} label={email} key={email} />
          ))}
        </div>
        <div>
          <Button
            onClick={handleReset}
            variant="outlined"
            color="primary"
            size="small">
            {t('Ok')}
          </Button>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={classes.container}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {error && (
        <Typography className={classes.error} variant="inherit" color="error">
          {t('validation:{{ email }} is not a valid e-mail address', {
            email: inputValue
          })}
        </Typography>
      )}
      <ChipInput
        value={emails}
        onAdd={chip => handleAddEmail(chip)}
        onDelete={chip => handleDeleteEmail(chip)}
        fullWidth
        fullWidthInput
        blurBehavior="add"
        newChipKeyCodes={[9, 13, 188, 32]} // Tab, Enter, Comma, Space
        inputValue={inputValue}
        placeholder={t('Enter e-mail address')}
        onUpdateInput={handleInputChange}
      />
      <div className={classes.info}>
        <Typography>
          {t('Separate multiple e-mail addresses with a comma')}
        </Typography>
      </div>
      <div className={classes.actions}>
        <Button
          className={classes.sendButton}
          disabled={emails.length === 0 || isLoading}
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleButtonClick}>
          <SendIcon /> {t('myDisk:Share')}
        </Button>
      </div>
    </div>
  );
};

export { ShareBasketView };
