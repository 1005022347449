import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useRouter from 'utils/useRouter';
import { RootState } from '../../store';
import ErrorMobileUserAccessDenied from '../../views/ErrorMobileUserAccessDenied';
import { ROLES } from '../../modules/auth';

const AuthGuard = (props: any) => {
  const { children } = props;
  const user = useSelector((state: RootState) => state.auth.user);
  const router = useRouter();

  useEffect(() => {
    if (!user) {
      router.history.push('/auth/login');
    }
  }, [router, user]);

  if (!user) {
    return null;
  }

  if (
    Array.from(user.roles || []).some(role => role === ROLES.MOBILE_APP_USER)
  ) {
    return <ErrorMobileUserAccessDenied />;
  }

  return <>{children}</>;
};

export default AuthGuard;
