import React from 'react';
import { ReactComponent as Doc } from './Icons/docx.svg';
import { ReactComponent as File } from './Icons/file.svg';
import { ReactComponent as Jpg } from './Icons/jpg.svg';
import { ReactComponent as Key } from './Icons/key.svg';
import { ReactComponent as Mp3 } from './Icons/mp3.svg';
import { ReactComponent as Mp4 } from './Icons/mp4.svg';
import { ReactComponent as Numbers } from './Icons/numbers.svg';
import { ReactComponent as Pages } from './Icons/pages.svg';
import { ReactComponent as Pdf } from './Icons/pdf.svg';
import { ReactComponent as Ppt } from './Icons/ppt.svg';
import { ReactComponent as Txt } from './Icons/txt.svg';
import { ReactComponent as Xls } from './Icons/xls.svg';
import { ReactComponent as Zip } from './Icons/zip.svg';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  icon: {
    width: 24,
    height: 24
  }
}));

interface Props {
  type: string;
  className?: string;
}

const FileIcon: React.FC<Props> = ({ type, className }) => {
  const classes = useStyles();
  let Icon = File;

  switch (type) {
    case 'doc':
    case 'docx': {
      Icon = Doc;
      break;
    }
    case 'jpg':
    case 'jpeg': {
      Icon = Jpg;
      break;
    }
    case 'key': {
      Icon = Key;
      break;
    }
    case 'mp3': {
      Icon = Mp3;
      break;
    }
    case 'mp4': {
      Icon = Mp4;
      break;
    }
    case 'numbers': {
      Icon = Numbers;
      break;
    }
    case 'pages': {
      Icon = Pages;
      break;
    }
    case 'pdf': {
      Icon = Pdf;
      break;
    }
    case 'ppt': {
      Icon = Ppt;
      break;
    }
    case 'txt': {
      Icon = Txt;
      break;
    }
    case 'xlsx':
    case 'xls': {
      Icon = Xls;
      break;
    }
    case 'zip': {
      Icon = Zip;
      break;
    }
  }

  return <Icon className={clsx(classes.icon, className)} />;
};

export default FileIcon;
