interface DirsConfig {
  types: {
    [key: string]: string;
  };
}

export default {
  types: {
    general: 'General',
    entity: 'Entity',
    'object-type': 'Object Type',
    'unique-object': 'Object ID',
    'inherited-type': 'Inherited Type'
  }
} as DirsConfig;
