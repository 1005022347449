import React, { useEffect, useState } from 'react';
import { Select } from 'components';
import { Dir } from '../../../../../modules/dirs';
import {
  useDirUsersAccess,
  useUserAccessActions,
  useDirUsersBlocked,
  useUserBlockedActions,
  UsersActions,
  UsersStateData
} from '../../../../../modules/dirs-access/hooks';
import { Skeleton } from '@material-ui/lab';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  createStyles,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useUsersList } from '../../../../../modules/users/hooks';
import { User } from '../../../../../modules/users';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: '35vh',
      padding: theme.spacing(2, 0)
    },
    table: {
      margin: theme.spacing(2, 0)
    },
    scrollbarContent: {
      maxHeight: '60vh'
    }
  })
);

interface Props {
  dir: Dir;
  actions: UsersActions;
  assignedUsers: UsersStateData;
}

const UsersManagement: React.FC<Props> = ({ dir, actions, assignedUsers }) => {
  const classes = useStyles();
  const { t } = useTranslation('users');
  const [value, setValue] = useState();
  const { fetchUsers, users, isLoading } = useUsersList();
  const [sortDir, setSortDir] = useState<'asc' | 'desc'>('asc');
  const [sortField, setSortField] = useState<keyof User>('firstName');

  useEffect(() => {
    fetchUsers();
    actions.fetch({
      dirId: dir.id
    });
    // eslint-disable-next-line
  }, []);

  const handleSortChange = (field: keyof User) => {
    const nextSortDir = sortDir === 'asc' ? 'desc' : 'asc';
    setSortDir(nextSortDir);
    setSortField(field);
  };

  const handleAddUser = (id: number) => {
    const user = users.find(item => +item.id === +id);

    actions.create({
      dirId: dir.id,
      userId: id,
      user
    });
  };

  const handleDeleteUser = (user: User) => {
    actions.remove({
      dirId: dir.id,
      userId: user.id
    });
  };

  const handleChange = (item: any) => {
    if (item) {
      setValue('');
      handleAddUser(item.value);
    }
  };

  const getUserFullName = (user: User) => {
    const chunks = [];
    if (user.firstName) {
      chunks.push(user.firstName);
    }

    if (user.lastName) {
      chunks.push(user.lastName);
    }

    return chunks.join(' ');
  };

  const assignedUserItems = Array.from<User>(assignedUsers.users || []).sort(
    (a, b) => {
      if (a.email === b.email) {
        return 0;
      }

      const val = sortDir === 'asc' ? 1 : -1;
      return a.email < b.email ? val * -1 : val * 1;
    }
  );
  const assignedUserIds = assignedUserItems.map(item => item.id);
  const hasUsers = assignedUserIds.length > 0;

  const options = users
    .filter(item => !assignedUserIds.includes(item.id))
    .map(user => ({
      value: user.id,
      label: `${getUserFullName(user)} (${user.email})`
    }));

  if (isLoading || assignedUsers.isFetching) {
    return <Skeleton width="100%" height={48} />;
  }

  return (
    <div className={classes.root}>
      <Select
        options={options}
        placeholder={t('Search users')}
        noOptionsMessage={() => t('No users found')}
        value={value}
        onChange={handleChange}
      />
      <PerfectScrollbar className={classes.scrollbarContent}>
        {hasUsers && (
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortField === 'firstName'}
                    direction={sortDir}
                    onClick={() => handleSortChange('firstName')}>
                    {t('Name')}
                  </TableSortLabel>
                </TableCell>
                <TableCell colSpan={2}>
                  <TableSortLabel
                    active={sortField === 'email'}
                    direction={sortDir}
                    onClick={() => handleSortChange('email')}>
                    {t('Email')}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignedUserItems.map(user => (
                <TableRow key={user.id}>
                  <TableCell>{getUserFullName(user) || '-'}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteUser(user)}>
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </PerfectScrollbar>
    </div>
  );
};

interface UsersManagementProps {
  dir: Dir;
}

const AccessUsersManagement: React.FC<UsersManagementProps> = ({ dir }) => {
  const actions = useUserAccessActions();
  const assignedUsers = useDirUsersAccess(dir);

  return (
    <UsersManagement
      dir={dir}
      actions={actions}
      assignedUsers={assignedUsers}
    />
  );
};

const BlockedUsersManagement: React.FC<UsersManagementProps> = ({ dir }) => {
  const actions = useUserBlockedActions();
  const assignedUsers = useDirUsersBlocked(dir);
  return (
    <UsersManagement
      dir={dir}
      actions={actions}
      assignedUsers={assignedUsers}
    />
  );
};

export { AccessUsersManagement, BlockedUsersManagement };
