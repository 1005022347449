import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useRouter from 'utils/useRouter';
import { RootState } from '../../store';

const GuestGuard = (props: any) => {
  const { children } = props;
  const user = useSelector((state: RootState) => state.auth.user);
  const router = useRouter();

  useEffect(() => {
    if (user) {
      router.history.push('/');
    }
  }, [router, user]);

  if (user) {
    return null;
  }

  return <>{children}</>;
};

export default GuestGuard;
