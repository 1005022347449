import React, { useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  Button,
  Grid,
  Tooltip,
  Theme
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { File } from 'modules/files';
import { useTranslation } from 'utils/translation';
import { makeStyles } from '@material-ui/styles';
import { useSingleFile } from '../../../../../modules/files/hooks';
import { ModalClose } from '../../../../../components';
import { useShouldModalBeFullscreen } from '../../../../../utils/media-query';

interface Props {
  open: boolean;
  onClose: () => void;
  file: File;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(2, 0)
  }
}));

export const ShareFileModal = ({
  file: resourceFile,
  open,
  onClose
}: Props) => {
  const fullScreen = useShouldModalBeFullscreen();
  const { t } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef<HTMLInputElement>();
  const [copied, setCopied] = useState(false);
  const file = useSingleFile(resourceFile.id);

  const handleCopyClipboard = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
      setCopied(true);
    }
  };

  useEffect(() => {
    let timeout: any;
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false);
      }, 2000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [copied]);

  return (
    <Dialog
      aria-labelledby="share-file-modal"
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="sm">
      <ModalClose onClose={onClose} />
      <DialogTitle disableTypography>
        <Typography align="center" gutterBottom variant="h3">
          {t('Shared link')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {!file.shareUrl && <Skeleton height={56} />}
        {file.shareUrl && (
          <>
            <Tooltip
              title={t('Copied to clipboard!')}
              placement="top-end"
              open={copied}
              disableFocusListener
              disableHoverListener
              disableTouchListener>
              <TextField
                onClick={handleCopyClipboard}
                inputRef={inputRef}
                inputProps={{
                  readOnly: true
                }}
                variant={'outlined'}
                fullWidth
                value={file.shareUrl}
              />
            </Tooltip>
            <Grid container justify="center">
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={handleCopyClipboard}>
                {t('Copy to clipboard')}
              </Button>
            </Grid>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
