import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import useRouter from 'utils/useRouter';

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
const APP_NAME = process.env.REACT_APP_APP_NAME;

interface PageProps {
  title: string;
  children: any;
  [key: string]: any;
}

const Page = (props: PageProps) => {
  const { title, children, ...rest } = props;

  const router = useRouter();

  useEffect(() => {
    if (NODE_ENV !== 'production') {
      return;
    }

    // @ts-ignore
    if (window.gtag) {
      // @ts-ignore
      window.gtag('config', GA_MEASUREMENT_ID, {
        page_path: router.location.pathname,
        page_name: title
      });
    }
  }, [title, router]);

  return (
    <div {...rest}>
      <Helmet>
        <title>{APP_NAME}</title>
      </Helmet>
      {children}
    </div>
  );
};

export default Page;
