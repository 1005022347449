import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexFlow: 'row nowrap',
    padding: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

interface Props {
  color?: 'primary' | 'secondary' | 'inherit';
  disableShrink?: boolean;
  size?: number | string;
}

const Loader: React.FC<Props> = props => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress {...props} />
    </div>
  );
};

export default Loader;
